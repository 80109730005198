import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {Grid, Button, Typography, Paper, Modal, Backdrop, Fade, TextField, makeStyles } from '@material-ui/core';
import ReactQuill from 'react-quill';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '90vw',
        margin: 'auto',
        marginTop: '20px',
    },
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        width: '100%',
        maxWidth: '90vw',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    table: {
        display: 'flex',
        justifyContent: 'center',
    },
    createButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    modal: {
        minWidth: '500px',
        minHeight: '400px',
        width: '40vw',
        height: '50vh',
    },
}));

function CompletionModal(props) {
    const classes = useStyles();

    const [emailContents, setEmailContents] = useState(null)
    const [currentStep, setCurrentStep] = useState(null)

    const defaultEmailContents = {
        subject: `Invoice for ${props.customer?.name} from GeeTee Holdings`, 
        message: `<p><b>Attached is the invoice for ${props.customer?.name} for the 
        ${moment(props.invoiceFilter.startDate).format('MMMM D, YYYY')} to the ${moment(props.invoiceFilter.endDate).format('MMMM D, YYYY')}</b></p>
        <p>Any questions or concerns can be directed to admin@geetee.ca.</p>`, 
    }

    //sets values to default
    useEffect(() => {
        setCurrentStep(0);
        setEmailContents(defaultEmailContents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open])

    const emailStep = (
        <Grid container justifyContent='center'>
            <Grid container style={{marginBottom: '10px'}}>
                <Typography style={{fontWeight: 'bold'}}>
                    Recipients: 
                </Typography>
            
                <Typography >
                    {props.customer?.contacts.filter(x=> x.invoiceContact || x.ccToInvoice).map((contact) => { return contact.email }).join(', ')}
                </Typography>
            </Grid>

            <Grid item xs={12} style={{marginBottom: '10px'}} className={classes.table}>
                <TextField
                    id="subject"
                    label="Subject"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={emailContents?.subject}
                    onChange={(e) => setEmailContents({...emailContents, subject: e.target.value})}
                />
            </Grid>

            <Grid item xs={12} className={classes.table}>
                <ReactQuill 
                    //ref={quill}
                    //style={{ width: '100%', height: '300px', marginBottom:'50px'}}
                    theme="snow" 
                    style={{width: '100%', }}
                    placeholder='Additional Instructional Message'
                    defaultValue={emailContents?.message} 
                    //value={content}
                    preserveWhitespace={false}
                    // set up this way as quill does some wonky stuff making onChange call at unexpected times
                    onChange={(html, delta, source) => {
                        if (source === 'user') {
                            setEmailContents({...emailContents, message: html})
                        }
                    }}
                    //modules={modules}
                    //formats={formats}
                />
            </Grid>
        </Grid>
    )

    const completionStep = (
        <Grid container justifyContent='center'>
            <Grid item>
                <Typography style={{fontWeight: 'bold', borderBottom: '1px solid black', marginBottom: '10px'}} align='center'>
                    Are you sure, this will
                </Typography>

                <Typography align='left' style={props.completionProgress >= 2 ? {color: 'green'} : null}>
                    - Generate the invoice number
                </Typography>

                <Typography align='left' style={props.completionProgress >= 3 ? {color: 'green'} : null}>
                    - Save the invoice
                </Typography>

                <Typography align='left' style={props.completionProgress >= 4 ? {color: 'green'} : null}>
                    - Set each tickets as posted
                </Typography>
     
                <Typography align='left' style={props.completionProgress >= 5 ? {color: 'green'} : null}>
                    {`- Email to these emails: `}  
                    {props.customer?.contacts.filter(x=> x.invoiceContact || x.ccToInvoice).map((contact) => { return contact.email }).join(', ')}
                </Typography>
            </Grid>
        </Grid>
    )

    return (
        <>
        <Modal
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 0,
            }}
            open={props.open}
            onClose={() => { props.setOpen(false) }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500,}}
        >
            <Fade in={props.open}>
                <Paper className={classes.modal} style={{position: 'relative'}}>

                    <Grid container >

                        {/* title section */}   
                        <Grid container justifyContent='center' style={{padding: '16px'}}>
                            <Typography style={{fontSize: '20px', fontWeight: 'bold'}}>{currentStep === 0 ? "Email" : "Completion"}</Typography>
                        </Grid>


                        {/* error section */}
                        <Grid container style={{paddingRight: '16px', paddingLeft: '16px'}}>
                            <Grid container>
                                {props.customer?.contacts.find(x=> x.invoiceContact) === undefined &&
                                    <Typography style={{color: 'red'}}>
                                        <small>
                                            Cannot continue without a customer invoice contact
                                        </small>
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        {/* content */}
                        <Grid container style={{padding: '16px'}}>
                            {/* {currentStep === 0 && confirmationStep} */}
                            {currentStep === 0 && emailStep}
                            {currentStep === 1 && completionStep}
                        </Grid> 

                        {/* buttons */}
                        <Grid container style={{padding: '16px', position: 'absolute', right: 0, bottom: 0, }}>
                            <Grid item xs={6}>
                                <Button 
                                    onClick={() => { setCurrentStep(currentStep - 1) }} 
                                    //style={props.completionProgress !== 0 ? {marginRight: '5px'} : {marginRight: '5px', color: 'white', backgroundColor: 'red'}}
                                    variant="contained"
                                    disabled={props.completionProgress !== 0 || currentStep === 0}
                                >
                                    Back
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container justifyContent='flex-end'>
                                    <Button
                                        //startIcon={<CheckIcon/>}
                                        variant='contained'
                                        disabled={props.completionProgress !== 0 || props.customer?.contacts.find(x=> x.invoiceContact) === undefined}
                                        onClick={(e) => { 
                                            currentStep !== 1 ? setCurrentStep(currentStep + 1) : props.confirmFunction(emailContents.subject, emailContents.message); 
                                        }}
                                    >
                                        {currentStep === 1 
                                        ? 
                                            props.completionProgress === 0 ? "Confirm" : props.completionProgress === 5 ? "Success" : "Processing" 
                                        :
                                            "Next"
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Paper>
            </Fade>
        </Modal>

        </>
    )
}

export default withRouter(CompletionModal)