import React, { useState, useCallback, useEffect }  from 'react';
import { makeStyles } from '@material-ui/core';
import { Checkbox, FormControlLabel, Grid, TextField, Typography, IconButton,
 Select, FormControl, Button, Card, CardContent, CardActions, InputLabel, TableCell, TableHead, TableRow, Table, Paper, TableContainer, TableBody,
Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Divider, Box, Snackbar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { firestore } from '../../../../firebase/firebase';
import _ from 'lodash'
import CustomerSelector from '../../CustomerSelector/CustomerSelector';
import { useCustomer } from '../../../../providers/CustomerContext';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tabBar: {
        width: '100%',
        maxWidth: '1000px',
    },
    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '10px',
        width: '100%',
        maxWidth: '1000px',
        backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const InformationTab = (props) => {
    const classes = useStyles();
    const [customerData, setCustomerData] = useState(props.customerData);
    const [state, setState] = React.useState({ checkedSame: false, checkedActive: props.customerData.isActive });
    const [open, setOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [rateInfo, setRateInfo] = React.useState({})
    const [oldRate, setOldRate] = React.useState({})
    const [editing, setEditing] = React.useState(false);
    const [saveState, setSaveState] = useState(props.saveState);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [showSelector, setShowSelector] = useState(false);
    const { customers, getCustomers } = useCustomer();

    const [addressBook, setAddressBook] = useState(props.customerData.addressBook || []);

    const openSelector = () => {
        setShowSelector(true)
    }

    const closeSelector = () => {
        setShowSelector(false)
    }


    useEffect(() => {
        setCustomerData(props.customerData)
        setState({ ...state, checkedActive: props.customerData.isActive })
        //eslint-disable-next-line
    }, [props.customerData])

    useEffect(() => {
        updateCustomerData({ ...customerData, addressBook: addressBook })
        //props.setCustomerData({ ...props.customerData, addressBook: addressBook })
        if(addressBook.length > 0){
            updateCustomerData({ ...customerData, isParent: true })
        }
        //eslint-disable-next-line
    }, [addressBook])

    useEffect(() => {
        getCustomers();
        //eslint-disable-next-line
    }, [])

    const isChild = () => {
        if(customers.filter(custy => custy?.addressBook && custy.addressBook.includes(customerData.docId)).length > 0)
        {
            return true
        } else {
            return false
        }
    }

    const findParent = () => {
        return customers.filter(custy => custy?.addressBook && custy.addressBook.includes(customerData.docId))[0]
    }
    
    //get link to parent and navigate to it
    const goToParent = () => {
        const parent = findParent()
        props.history.push(`/dashboard/customers/form?docId=${parent.docId}`)
    }

    const goToChild =(docId) => {
        props.history.push(`/dashboard/customers/form?docId=${docId}`)
    }

    //const handleCheckChange = (event) => {
    //    setState({ ...state, [event.target.name]: event.target.checked });
    //};

    const handleClickOpen = () => { 
        setEditing(false);
        setRateInfo({name: '', rate: 0, per: '', typeofrate: ''})
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditOpen = (rate) => {
        setRateInfo(rate)
        setOldRate(rate)
       
        setOpen(true);
        setEditing(true);
    };

    const handleDeleteOpen = (rate) => {

        setDeleteOpen(true);
        setRateInfo(rate)
    };
    
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const handleSubmit = (event) => {
        const oldRates = props.customerData.rates
        let newRates = oldRates
        setRateInfo({ ...rateInfo, 'id': oldRates.length })
        newRates.push(rateInfo)
        props.updateCustomerData({ ...props.customerData, rates: newRates })
        firestore.collection('customers').doc(props.customerData.docId)
            .update(props.customerData)
        setRateInfo({name: '', rate: 0, per: '', typeofrate: ''})
    }

    const handleDelete = (event) => {
        const oldRates = props.customerData.rates
        const index = oldRates.indexOf(rateInfo)
        if (index > -1) { oldRates.splice(index, 1); }
        let newRates = oldRates
        props.updateCustomerData({ ...props.customerData, rates: newRates })
        firestore.collection('customers').doc(props.customerData.docId)
            .update(props.customerData)
        setRateInfo({name: '', rate: 0, per: '', typeofrate: ''})
    }

    const handleEdit = (event) => {        
        const oldRates = props.customerData.rates    
        const index = oldRates.indexOf(oldRate)    
        if (index > -1) {
            oldRates.splice(index, 1);
          }
        let newRates = oldRates
        newRates.push(rateInfo)

        props.updateCustomerData({ ...props.customerData, rates: newRates })
        firestore.collection('customers').doc(props.customerData.docId)
            .update(props.customerData)
        setRateInfo({name: '', rate: 0, per: '', typeofrate: ''})
    }


    const updateCustomerData = useCallback((e) => {
            setCustomerData(e)
        },
        [],
    );
    const changeHandler = (event) => {
        if (event.target.name.includes('.')) {
            //substring to get the first part of the name
            let option1 = event.target.name.substring(0, event.target.name.indexOf('.'))
            //substring to get the second part of the name
            let option2 = event.target.name.substring(event.target.name.indexOf('.') + 1)
            
            //updateCustomerData using the spread operator to keep the other values
            updateCustomerData({ ...customerData, [option1]: { ...customerData[option1], [option2]: event.target.value } })


        }
        else{
            updateCustomerData({ ...customerData, [event.target.name]: event.target.value })
        }
        
    }

    const confirmSave = () => {
        props.setCustomerData(customerData);
        props.setSaveState(customerData);
        setSaving(true);
        firestore.collection('customers').doc(props.customerData.docId)
            .update(customerData)
            .then(() => {
                setSaveState(customerData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});
    };
    const cancelSave = () => {
        setCustomerData(saveState);
    };
    return (
        <Grid container spacing={2}>
            <CustomerSelector open={showSelector} updateCustomerData={updateCustomerData}
            callbackList={addressBook} setCallbackList={setAddressBook} currentCustomer={customerData} handleClose={closeSelector}/>
            {/* delete modal */}
            <Dialog open={deleteOpen} onClose={handleClose}>
                <DialogTitle>Delete Rate</DialogTitle>
                <DialogContent>
                <Typography>Are you sure you want to delete rate: {rateInfo.name}?</Typography>
                    <DialogContentText>
                        This is NOT reversable!
                    </DialogContentText>
                    
                    <DialogActions>
                        <Button variant="contained" color="secondary" size="small" onClick={handleDeleteClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" size="small" onClick={handleDelete}>
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            {/* add/edit rate */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{!editing ? "Add" : "Edit"} Rate</DialogTitle>
                <DialogContent>
                <DialogContentText>
                {!editing ? "Please enter the details of the new rate." : "Edit the current rate."}
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    value={rateInfo.name}
                    error={rateInfo.name === ''}
                    helperText={rateInfo.name === '' ? "Rate requires a name": ''}
                    onChange={(event) => { setRateInfo({ ...rateInfo, 'name': event.target.value }) }}
                />
                <Grid item xs={12}>
                    <Typography style={{ fontSize: 12 }}>Type of Rate  </Typography>
                    <Select                                                                                             
                        onChange={(event) => { setRateInfo({ ...rateInfo, 'typeofrate': event.target.value }) }}
                        value={rateInfo.typeofrate}   
                        id="typeofrate"
                        size="small" fullWidth                         
                    >
                     <option value=""></option>
                     <option value="Cartage Rate">Cartage Rate</option>
                     <option value="Material Rate">Material Rate</option>
                    </Select>
                </Grid>
                <Grid container spacing={2}> 
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="rate"
                            label="pay rate"
                            value={rateInfo.rate}
                            type="decimal"
                            onChange={(event) => { setRateInfo({ ...rateInfo, 'rate': event.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="per"
                            label="Per"
                            value={rateInfo.per}
                            onChange={(event) => { setRateInfo({ ...rateInfo, 'per': event.target.value }) }}
                        />
                    </Grid>       
                </Grid>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                    Cancel
                </Button>
                <Button disabled={rateInfo.name === ''} variant="contained" color="primary" size="small" onClick={!editing ? handleSubmit : handleEdit}>
                    Submit
                </Button>
                </DialogActions>
            </Dialog>

            <Grid item sm={8} xs={12}>
            <FormControlLabel style={state.checkedActive ?{size: '20px', margin: '4px', paddingRight:'12px', border: '2px solid lightGreen', borderRadius: '5px', padding: '5px'}
            : {size: '20px', margin: '4px', paddingRight:'12px', border: '2px dashed lightGrey', borderRadius: '5px', padding: '5px'}}
                control={
                    <Checkbox 
                        style={state.checkedActive ?{transform: "scale(2)", color:'green', marginRight:'5px'} : {transform: "scale(2)", color:'black', marginRight:'5px'}} 
                        checked={state.checkedActive} 
                        onChange={(e) => {props.updateCustomerData({...props.customerData, isActive: e.target.checked}) }} 
                        name="checkedActive" 
                        //color="primary" 
                       
                    />
                }
                label={<Box component="div" fontSize={25}>
                {state.checkedActive ?'Customer is ACTIVE':'Customer is INACTIVE'}
                </Box>}
                />
                 {isChild() ?<Grid container justifyContent='flex-end'>
                    <Button variant='contained' onClick={()=>goToParent()}>Go To Parent Customer</Button>
                </Grid>:null}
                <Divider style={{margin:'4px'}}/>
                {props.edit
                    ? <React.Fragment>
                        
                        <TextField
                            style={{ marginBottom: '0px' }}
                            label='Customer Name'
                            name="name"
                            value={customerData.name}
                            variant='standard'
                            onChange={(event) => { changeHandler(event) }}
                            inputProps={{ style: { fontSize: '2.125rem' } }} />
                        <Typography align='left' variant='h6'>Job Number: <TextField
                            style={{ marginBottom: '0px' }}
                            value={customerData.jobNumber}
                            name="jobNumber"
                            variant='standard'
                            onChange={(event) => { changeHandler(event) }}
                             /></Typography>    
                        <Divider/>

                        {/* <Typography align='left' variant='h6'>Email: <TextField
                            style={{ marginBottom: '0px' }}
                            value={customerData.email}
                            name="email"
                            variant='standard'
                            onChange={(event) => { changeHandler(event) }}
                             /></Typography>     */}
                        <Divider/>

                        <FormControl className={classes.formControl} style={{margin:'10px', width:'50%'}}>
                            <InputLabel htmlFor="age-native-simple">Customer Type</InputLabel>
                            <Select
                            native
                            name='customerType'
                            size="small"
                            value={customerData.customerType}
                            variant="outlined"
                            onChange={(event) => { changeHandler(event) }}
                            >
                            <option value={"Customer"}>Customer</option>
                            <option value={"Supplier"}>Supplier</option>
                            <option value={"Service"}>Service</option>
                            </Select>
                        </FormControl>
                        <div style={{border:'1px solid #d4d4d4', padding:'10px', borderRadius:'4px', margin:'5px'}}>
                            <Typography style={{margin:'10px'}}>Mailing Address</Typography>
                            <Divider></Divider>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='Street Number'
                                    margin="dense"
                                    name="streetNumber"
                                    value={customerData.streetNumber}
                                    variant='outlined'
                                    onChange={(event) => { changeHandler(event) }}
                                   // inputProps={{ style: { fontSize: '1.5rem' } }} 
                                    />
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='Street Name'
                                    value={customerData.streetName}
                                    name="streetName"
                                    variant='outlined'
                                    onChange={(event) => { changeHandler(event) }}
                                    //inputProps={{ style: { fontSize: '1.5rem' } }} 
                                    margin="dense"/>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='City'
                                    value={customerData.city}
                                    name="city"
                                    variant='outlined'
                                    onChange={(event) => { changeHandler(event) }}
                                    margin="dense" />
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='Province'
                                    value={customerData.province}
                                    name="province"
                                    variant='outlined'
                                    onChange={(event) => { changeHandler(event) }}
                                    margin="dense" />
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='Postal Code'
                                    value={customerData.postalCode}
                                    variant='outlined'
                                    name="postalCode"
                                    onChange={(event) => { changeHandler(event) }}
                                    margin="dense" />
                            </div>
                        </div>
                        <div style={{border:'1px solid #d4d4d4', padding:'10px', borderRadius:'4px', margin:'5px', backgroundColor: state.checkedSame ? 'grey' : 'white'}}>
                        <Typography style={{margin:'10px'}}>Delivery Address</Typography>
                        <Divider style={{marginBottom:'8px'}}></Divider>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='Street Number'
                                    value={state.checkedSame ? customerData.streetNumber : customerData.deliveryAddress.streetNumber}
                                    variant={state.checkedSame ? 'filled' : 'outlined'}
                                    disabled={state.checkedSame}
                                    name='deliveryAddress.streetNumber'
                                    onChange={(event) => { changeHandler(event) }}
                                    margin="dense" />
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='Street Name'
                                    value={state.checkedSame ? customerData.streetName : customerData.deliveryAddress.streetName}
                                    variant={state.checkedSame ? 'filled' : 'outlined'}
                                    disabled={state.checkedSame}
                                    name='deliveryAddress.streetName'
                                    onChange={(event) => { changeHandler(event) }}
                                    margin="dense" />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='City'
                                    value={state.checkedSame ? customerData.city : customerData.deliveryAddress.city}
                                    variant={state.checkedSame ? 'filled' : 'outlined'}
                                    disabled={state.checkedSame}
                                    name='deliveryAddress.city'
                                    onChange={(event) => { changeHandler(event) }}
                                    margin="dense"/>
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='Province'
                                    value={state.checkedSame ? customerData.province : customerData.deliveryAddress.province}
                                    variant={state.checkedSame ? 'filled' : 'outlined'}
                                    disabled={state.checkedSame}
                                    name='deliveryAddress.province'
                                    onChange={(event) => { changeHandler(event) }}
                                    margin="dense" />
                                <TextField
                                    style={{ marginBottom: '8px' }}
                                    label='Postal Code'
                                    value={state.checkedSame ? customerData.postalCode : customerData.deliveryAddress.postalCode}
                                    variant={state.checkedSame ? 'filled' : 'outlined'}
                                    disabled={state.checkedSame}
                                    onChange={(event) => { changeHandler(event) }}
                                    name="deliveryAddress.postalCode"
                                    margin="dense" />
                            </div>
                            <FormControlLabel
                                control={<Checkbox checked={state.checkedSame} onChange={(e)=>{
                                    setState({ ...state, [e.target.name]: e.target.checked });
                                    if(e.target.checked){
                                        updateCustomerData({ ...customerData, deliveryAddress: customerData })
                                    } else {
                                        updateCustomerData({ ...customerData, deliveryAddress: {streetNumber: '', streetName: '', city: '', province: '', postalCode: ''} })
                                    }

                                }} name="checkedSame" color="primary" />}
                                label="Same as Mailing Address"
                            />
                        </div>
                        
                    </React.Fragment>
                    : <React.Fragment>
                        
                        
                        <Typography align='left' variant='h4'>{props.customerData.name}</Typography>
                        <Typography align='left' variant='h6'>Job Number: {props.customerData.jobNumber}</Typography>
                        {/* <Typography align='left' variant='h6'>Email: {props.customerData.email}</Typography> */}
                        <Divider></Divider>
                       
                        <Grid container spacing={1}>
                            <Grid container item>
                                <Typography style={{fontStyle:'italic'}}>{props.customerData.customerType}</Typography>
                            </Grid>
                        </Grid>
                        
                    </React.Fragment>}
            </Grid>

            
            {props.edit || props.loading
                ? null
                :
            <Grid container spacing={2}>
                <Grid container>
                    <Grid item xs={5} style={{margin:'10px', marginLeft: '23px'}}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.title} color="textPrimary" gutterBottom>
                                Mailing Address
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                {props.customerData.streetNumber} {props.customerData.streetName}
                                </Typography>
                                <Typography variant="body2" component="p">
                                {props.customerData.city ? props.customerData.city : "Winnipeg"}
                                    {props.customerData.province ? ", " + props.customerData.province : "MB"}
                                    {props.customerData.postalCode ? "  " + props.customerData.postalCode : ""}
                                <br />

                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small"></Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={5} style={{margin:'10px'}}>
                        <Card className={classes.root}>
                            <CardContent tag="div">
                                <Typography className={classes.title} color="textPrimary" gutterBottom>
                                Delivery Address
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    {props.customerData.deliveryAddress && props.customerData.deliveryAddress.streetNumber ? props.customerData.deliveryAddress.streetNumber : ''} 
                                    {props.customerData.deliveryAddress && props.customerData.deliveryAddress.streetName ? props.customerData.deliveryAddress.streetName : ''}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {props.customerData.deliveryAddress.city ? props.customerData.deliveryAddress.city : ""}
                                    {props.customerData.deliveryAddress.province ? ", " + props.customerData.deliveryAddress.province : ""}
                                    {props.customerData.deliveryAddress && props.customerData.deliveryAddress.postalCode ? " " + props.customerData.deliveryAddress.postalCode : ""}
                                <br />

                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small"></Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    
                </Grid>
            </Grid>}
            <Grid container style={{margin: '15px', overflowY:'hidden'}}>
                <TableContainer component={Paper} style={{maxHeight:'400px'}}>
                    <Grid container justifyContent='flex-end'>
                        <Grid container justifyContent='flex-end'item xs={12}>
                            <Button size='small' disabled={!props.edit || isChild()} variant="contained" onClick={() => openSelector()} startIcon={<AddIcon/>}>Add Sub Customers</Button>
                        </Grid>
                        {isChild() ?
                        <Grid container justifyContent='flex-end'item xs={12}>
                            <Typography variant={'caption'} style={{color:'red'}}>Has a Parent Customer</Typography>
                        </Grid>:null}
                    </Grid>
                    <Table stickyHeader className={classes.table} aria-label="simple table" size="small">
                        <TableHead>
                            <Typography
                                style={{ marginLeft:'5px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                                >
                                Sub Jobs
                            </Typography>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Job Number</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.customerData.addressBook.length > 0 ? customers.filter(custy =>
                            addressBook.includes(custy.docId)).map((addr, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {addr.name}
                                    </TableCell>
                                    <TableCell >{addr.jobNumber}</TableCell>
                                    <TableCell >{addr.fullStreet}</TableCell>
                                    <TableCell align="right">
                                        <IconButton variant='contained' onClick={()=>goToChild(addr.docId)}><OpenInBrowserIcon/></IconButton>
                                    </TableCell>
                                </TableRow>
                            )):
                            <Grid container justifyContent='center'>
                                <Grid item xs={6} style={{margin:'16px'}}><Typography variant="caption">No Data Found..</Typography></Grid>
                            </Grid>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            
            </Grid>
            <Grid container style={{margin: '15px'}}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table" size="small">
                        <TableHead>
                        <TableRow>
                            <TableCell>Name of Rate</TableCell>
                            <TableCell>Type of Rate</TableCell>
                            <TableCell align="right">Pay Rate</TableCell>
                            <TableCell align="right">Per</TableCell>
                            {props.edit ? <React.Fragment><TableCell align="center">Delete</TableCell><TableCell align="center">Edit</TableCell></React.Fragment>
                            : null}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.customerData.rates.map((rate, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {rate.name}
                                    </TableCell>
                                    <TableCell >{rate.typeofrate}</TableCell>
                                    <TableCell align="right">${rate.rate}</TableCell>
                                    <TableCell align="right">{rate.per}</TableCell>
                                    {props.edit ?<React.Fragment>
                                    <TableCell align="center"><IconButton onClick={() => handleDeleteOpen(rate)} color="secondary"><DeleteForeverIcon/></IconButton></TableCell>
                                    <TableCell align="center"><IconButton onClick={() => handleEditOpen(rate)} color="secondary"><EditIcon/></IconButton></TableCell></React.Fragment>  : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            {props.edit ?
                <Grid item xs={6} style={{margin:'16px'}}>
                    <Button disabled={!props.edit} variant="contained" onClick={handleClickOpen} startIcon={<AddIcon/>}>Add Pay Rate</Button>
                </Grid> : null}
            </Grid>
            <Snackbar
                            open={!_.isEqual(customerData, saveState)}
                            message={saving ? 'Saving...' : 'Save Customer?'}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            action={
                                <React.Fragment>
                                    {saving
                                        ? null
                                        : <React.Fragment>
                                            <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                            <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                                        </React.Fragment>}
                                </React.Fragment>
                            } />
        </Grid>
    )
}
export default withRouter(InformationTab)