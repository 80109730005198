import React from 'react';
import {Grid, makeStyles, Button, Typography, Paper, TextField,  FormControl,
        Checkbox, FormControlLabel, FormLabel, RadioGroup, Radio, } from '@material-ui/core';
import { withRouter } from 'react-router';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash';

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import MultiSelectList from '../UI/MultiSelectList/MultiSelectList';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '90vw',
        margin: 'auto',
        marginTop: '20px',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    radioGroup: {
      border: '1px solid black', 
      padding: '10px',
      marginBottom: '10px',
    },
    rowGroup: {
      paddingTop: '10px', 
      paddingLeft: '20px', 
      paddingRight: '20px'
    },
  }));
  
const PayrollCard = (props) => {

    const classes = useStyles();
    //const theme = useTheme();
    //const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const onRowSelectionChange = (rows) => {
      props.setSelectedRow(rows)
    }

    return (  
      <Grid className={classes.content}>     
        <Grid container className={classes.backButton}>
          <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
        </Grid>

        <Paper>
          <Grid container className={classes.rowGroup}>

            <Grid item xs={12} md={9}>
                <Grid container justifyContent='column'>
                    <Grid item xs={4}>
                        {props.currentPayroll.reportDetail !== 'summary' &&
                        <FormControl>
                            <FormLabel style={{textAlign: 'center'}}>Statement Options</FormLabel>
                            <Grid container className={classes.radioGroup}>
                              <Grid item xs={12}>
                                <FormControlLabel 
                                    checked={props.currentPayroll.includeDollarAmount} 
                                    onChange={(e)=> { 
                                      let container = _.cloneDeep(props.currentPayroll)
                                      if(props.currentPayroll.includeVacactionPay === true){ container.includeVacactionPay = false;}
                                      container.includeDollarAmount = e.target.checked

                                      props.setCurrentPayroll({...container}) 
                                      sessionStorage.setItem("currentPayroll", JSON.stringify({...container})) 
                                    }} 
                                    control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />}/>}
                                    label="Include Dollar Amounts" 
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControlLabel 
                                    checked={props.currentPayroll.includePayrollUnits} 
                                    onChange={(e)=> { 
                                      props.setCurrentPayroll({...props.currentPayroll, includePayrollUnits: e.target.checked}) 
                                      sessionStorage.setItem("currentPayroll", JSON.stringify({...props.currentPayroll, includePayrollUnits: e.target.checked})) 
                                    }} 
                                    control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />}/>}
                                    label="Include Payroll Units" 
                                  />
                                </Grid>
                                {/* <Grid item xs={12}>
                                  <FormControlLabel 
                                    checked={props.currentPayroll.includeVacactionPay} 
                                    onChange={(e)=> { 
                                      props.setCurrentPayroll({...props.currentPayroll, includeVacactionPay: e.target.checked}) 
                                      sessionStorage.setItem("currentPayroll", JSON.stringify({...props.currentPayroll, includeVacactionPay: e.target.checked})) 
                                    }} 
                                    control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />}/>}
                                    label="Include Vacation Pay" 
                                  />
                                </Grid> */}
                            </Grid>
                        </FormControl>
                        }
                    </Grid>
                    
                    <Grid item xs={4}>
                        {/* <Grid container justifyContent='center'>
                        <FormControl>
                            <FormLabel style={{textAlign: 'center'}}>Report Detail</FormLabel>
                            <RadioGroup 
                            className={classes.radioGroup}
                            name="reportDetail" 
                            value={props.currentPayroll.reportDetail} 
                            onChange={(e)=> { 
                                props.setCurrentPayroll({...props.currentPayroll, reportDetail: e.target.value})
                                sessionStorage.setItem("currentPayroll", JSON.stringify({...props.currentPayroll, reportDetail: e.target.value})) 
                            }}
                            >
                            <FormControlLabel value="detail" control={<Radio />} label="Detail" />
                            <FormControlLabel value="summary" control={<Radio />} label="Summary" />
                            </RadioGroup>
                        </FormControl>
                        </Grid> */}

                    </Grid>

                    <Grid item xs={4}>
                        <Grid container justifyContent='center'>
                            <FormControl>
                                <FormLabel style={{textAlign: 'center'}}>Report Scope</FormLabel>
                                <RadioGroup 
                                className={classes.radioGroup}
                                name="reportScope" 
                                value={props.payrollFilter.reportScope} 
                                onChange={(e)=> { 
                                    props.setPayrollFilter({...props.payrollFilter, reportScope: e.target.value})
                                    sessionStorage.setItem("payrollFilter", JSON.stringify({...props.payrollFilter, reportScope: e.target.value})) 
                                }}
                                >
                                <FormControlLabel value="postedOnly" control={<Radio />} label="Include POSTED sales only" />
                                <FormControlLabel value="unpostedOnly" control={<Radio />} label="Include UPOSTED sales only" />
                                <FormControlLabel value="all" control={<Radio />} label="Include ALL sales" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container style={{paddingRight: '20px'}}>
                    <Grid container>
                        <Typography>Short Message</Typography>
                    </Grid>
                    <Grid container>
                        <TextField
                            variant='outlined'
                            fullWidth
                            minRows={6}
                            value={props.currentPayroll.shortMessage} 
                            onChange={(e)=> { 
                                props.setCurrentPayroll({...props.currentPayroll, shortMessage: e.target.value})
                                sessionStorage.setItem("currentPayroll", JSON.stringify({...props.currentPayroll, shortMessage: e.target.value})) 
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container style={{paddingRight: '20px'}}>
                  <FormControl>
                    <FormControlLabel 
                        checked={props.payrollFilter.findByTicketNumber} 
                        onChange={(e)=> { 
                          props.setPayrollFilter({...props.payrollFilter, findByTicketNumber: e.target.checked}) 
                          sessionStorage.setItem("payrollFilter", JSON.stringify({...props.payrollFilter, findByTicketNumber: e.target.checked})) 
                        }} 
                        control={<Checkbox/>}
                        label="Payroll and Post by individual Ticket Numbers or ANY Combination of Ticket Numbers instead of Operator Name." 
                    />
                  </FormControl>
                </Grid>
            </Grid>

            <Grid item xs={12} md={3} className={classes.rowGroup} style={{border: '1px solid black',}}>
              <MultiSelectList 
                onRowSelectionChange={onRowSelectionChange}
                style={{maxHeight: '400px'}}
                header={props.payrollFilter.findByTicketNumber ? 'Ticket Number' :' Operator Name'}
                //multiListDataTitle field needed for display
                listData={props.filteredListData}
              />
              
              <Button
                style={{ marginBottom: '5px'}}
                fullWidth
                variant='contained'
                disabled={props.selectedRow < 1}
                onClick={()=> {
                  props.handlePreviewPDF()
                }}
              >
                Preview the Invoice
              </Button>

              <Grid container>
                  <Grid item xs={5}>
                    <KeyboardDatePicker 
                        value={props.payrollFilter.startDate}
                        onChange={(value)=> { 
                          props.setPayrollFilter({...props.payrollFilter, startDate: value}) 
                          sessionStorage.setItem("payrollFilter", JSON.stringify({...props.payrollFilter, startDate: value})) 
                        }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{textAlign: 'center'}}>To</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <KeyboardDatePicker 
                      value={props.payrollFilter.endDate}
                      onChange={(value)=> { 
                        props.setPayrollFilter({...props.payrollFilter, endDate: value}) 
                        sessionStorage.setItem("payrollFilter", JSON.stringify({...props.payrollFilter, endDate: value})) 
                      }}
                    />
                  </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' style={{padding: '20px'}}>
            <Grid container justifyContent='space-evenly'>
                <FormControlLabel
                label={<Typography variant="h5">{props.payrollFilter.reportScope !== 'postedOnly' ? "POST the selected tickets as paid to the Operator" : "UNPOST the selected tickets as paid to the Operator"}</Typography>}
                control={
                    <Checkbox 
                        variant="contained" 
                        checked={props.currentPayroll.payToOperator}
                        onClick={(e) => {
                          props.setCurrentPayroll({...props.currentPayroll, payToOperator: e.target.checked})
                          //sessionStorage.setItem("currentPayroll", JSON.stringify({...props.currentPayroll, payToOperator: e.target.checked})) 
                        }}
                    >
                    </Checkbox>
                } />
          
                <FormControlLabel
                label={<Typography variant="h6">Hide Labour Disribution</Typography>}
                control={
                    <Checkbox 
                        variant="contained" 
                        checked={props.currentPayroll.hideLabourDistribution}
                        onClick={(e) => {
                          props.setCurrentPayroll({...props.currentPayroll, hideLabourDistribution: e.target.checked})
                          sessionStorage.setItem("currentPayroll", JSON.stringify({...props.currentPayroll, hideLabourDistribution: e.target.checked})) 
                        }}
                    >
                    </Checkbox>
                } />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }
  
  export default withRouter(PayrollCard);