import React, { useRef, useCallback } from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin  from '@fullcalendar/interaction';
import { firestore } from '../../../firebase/firebase';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';
import moment from 'moment';

const useStyles = makeStyles(() => ({
    loadingStyle: {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}));

const CalanderCard = (props) => {
    const classes = useStyles();
    const throwAsyncError = useAsyncError();

    const calanderContainerRef = useRef(null)

    const toggleLoading = useCallback((loading) => {
        if (calanderContainerRef.current) {
            if (loading) { calanderContainerRef.current.classList.add(classes.loadingStyle);}
            else { calanderContainerRef.current.classList.remove(classes.loadingStyle); }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calanderContainerRef]);

    return (
        <Paper style={{marginRight: '10px', padding: '10px'}}>
            <div ref={calanderContainerRef}>
                <FullCalendar
                    height={ props.height }
                    plugins={[ dayGridPlugin, interactionPlugin ]}
                    initialView="dayGridMonth"
                    loading={loading => toggleLoading(loading)}
                    events={ function(info, successCallback, failureCallback) {
                        firestore.collection('plannedDeliveries')
                        .where("date", ">=", moment(info.start.valueOf()).format("YYYY-MM-DD"))
                        .where("date", "<=", moment(info.end.valueOf()).format("YYYY-MM-DD"))
                        .get()
                        .then(snapshot => {
                            successCallback(
                                snapshot.docs.map((doc) => {
                                    return {
                                    ...doc.data(),
                                    'docId': doc.id,
                                    'title': doc.data().value?.replace(/<[^>]*>/g, ' ').replace(/&nbsp;/g, '\n')   //converts value to plaintext (for displaying in fullcalendar)
                                    }
                                })
                            )
                        })
                        .catch((e) => {
                            failureCallback(e);
                            throwAsyncError(new Error(e.message, e)); 
                        })
                    }}

                    displayEventTime={false}
                    eventDisplay='background'
                    headerToolbar={{
                        left: "title",
                        center: "",
                        right: "prev,next",
                    }}
                    dateClick={ (dateObj) => {
                        props.handleDateClick(moment(dateObj.date).format('YYYY-MM-DD').valueOf());
                    }}
                    //current workaround to alter background event styles
                    eventDidMount={ function(info) {
                        const eventEl = info.el;
                        // Add the green border
                        if (!info.event._def.extendedProps.published && info.event._def.extendedProps.value !== '' ) {
                            
                            // Set the opacity
                            eventEl.style.opacity = 1;

                            // Create a container for the published indicator
                            const publishedContainer = document.createElement('div');
                            publishedContainer.className = 'published-container';

                            // Create a small black rectangle
                            const blackRectangle = document.createElement('div');
                            blackRectangle.className = 'black-rectangle';
                            blackRectangle.style.backgroundColor = 'grey';

                            // Create the "PUBLISHED" text
                            const publishedText = document.createElement('div');
                            publishedText.className = 'published-text';
                            publishedText.textContent = 'DRAFT';

                            // Create a white checkmark icon
                            const checkmark = document.createElement('div');
                            checkmark.className = 'fc-event-checkmark';

                            // Position the checkmark in the bottom center
                            checkmark.style.position = 'absolute';
                            checkmark.style.bottom = '10%';
                            checkmark.style.left = '10%';
                            checkmark.style.transform = 'translateX(-50%)';
                            checkmark.style.width = '16px';
                            checkmark.style.height = '16px';
                            //checkmark.style.backgroundColor = 'black';
                            checkmark.style.borderRadius = '50%';
                            checkmark.style.padding = '2px';
                            //checkmark.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#26ff84" viewBox="0 0 16 16"><path d="M4.293 7.293a1 1 0 0 1 1.414 0L9 10.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-3.5-3.5a1 1 0 0 1 0-1.414z"/></svg>';
                            checkmark.innerHTML = '&nbsp;&nbsp;<small>X</small>'
                            checkmark.style.bottom = '2.5px';
                            checkmark.style.color = 'black'
                            // Append elements to the published container
                            blackRectangle.appendChild(publishedText);
                            blackRectangle.appendChild(checkmark);
                            publishedContainer.appendChild(blackRectangle);

                            // Append the published container to the event element
                            eventEl.appendChild(publishedContainer);
                        }
                        if (info.event._def.extendedProps.published) {
                            eventEl.style.border = '2px solid #26ff84';
                            
                            // Set the opacity
                            eventEl.style.opacity = 1;

                            // Create a container for the published indicator
                            const publishedContainer = document.createElement('div');
                            publishedContainer.className = 'published-container';

                            // Create a small black rectangle
                            const blackRectangle = document.createElement('div');
                            blackRectangle.className = 'black-rectangle';

                            // Create the "PUBLISHED" text
                            const publishedText = document.createElement('div');
                            publishedText.className = 'published-text';
                            publishedText.textContent = 'PUBLISHED';

                            // Create a white checkmark icon
                            const checkmark = document.createElement('div');
                            checkmark.className = 'fc-event-checkmark';

                            // Position the checkmark in the bottom center
                            checkmark.style.position = 'absolute';
                            checkmark.style.bottom = '10%';
                            checkmark.style.left = '10%';
                            checkmark.style.transform = 'translateX(-50%)';
                            checkmark.style.width = '16px';
                            checkmark.style.height = '16px';
                            //checkmark.style.backgroundColor = 'black';
                            checkmark.style.borderRadius = '50%';
                            checkmark.style.padding = '2px';
                            checkmark.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#26ff84" viewBox="0 0 16 16"><path d="M4.293 7.293a1 1 0 0 1 1.414 0L9 10.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-3.5-3.5a1 1 0 0 1 0-1.414z"/></svg>';

                            // Append elements to the published container
                            blackRectangle.appendChild(publishedText);
                            blackRectangle.appendChild(checkmark);
                            publishedContainer.appendChild(blackRectangle);

                            // Append the published container to the event element
                            eventEl.appendChild(publishedContainer);
                        }
                    

                    }}
                />
            </div>
        </Paper>
    )
}

export default CalanderCard;