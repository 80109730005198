import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid,} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { firestore, functions } from '../../../firebase/firebase';
import _ from 'lodash';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { useCustomer } from '../../../providers/CustomerContext';
import { useUser } from '../../../providers/UserContext';
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'

import GrainOrdersCreateCard from '../../components/Grain/GrainOrdersCreateCard';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1100px',
        margin: 'auto',
    },
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1000px',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const GrainOrdersNew = (props) => {
    const classes = useStyles();

    const { customers, loading, getCustomers } = useCustomer();
    const { getUsers, usersLoading, users } = useUser();
    const { units, unitsLoading, getUnits } = useUnitDescription();

    const newGrainOrder = functions.httpsCallable('createGrainOrder');

    const [grainOrderLoading, setGrainOrderLoading] = useState(true)
    //const [success, setSuccess] = useState(false)
    const [saving, setSaving] = useState(0);    // 0 false, 1 true, -1 success

    const initialgrainOrder = {
        geeTeeOrderNumber: "", 
        orderNumber: "",
        dateCreated: moment().valueOf(),
        ticketDate: moment().valueOf(),
        orderStatus: "active",
        orderPriority: "",

        customer: "",   //docId
        operator: "",   //uuid
        equipment: "",  //docId
        trailer1: "",   //docId
        trailer2: "",   //docId

        origin: "",  
        destination: "", 

        material: "",
        dollarFuelSurcharge: 0.00,
        percentageFuelSurcharge: 0.000,

        //owner
        cartageSales: 0.00,
        cartageSalesUnits: 0,
        ownerPer: "",
        cartageSalesTotal: 0.00,
        linkOperatorToSalesRate: false,  //checkbox bool
        operatorBaseMultiplier: 100.00,

        //operator
        operatorRate: 0,
        adjustedBase: 0.00,
        operatorUnits: 0,
        operatorPer: "",
        payroll: 0.00,

        instructions: "",

        orderType: "",
    }
    
    const [currentGrainOrder, setCurrentGrainOrder] = useState(initialgrainOrder)
    const [grainOrders, setGrainOrders] = useState([])
    const [places, setPlaces] = useState([])
    const [per, setPer] = useState([])
    const [materials, setMaterials] = useState([])
    const [orderNumberSaveState, setOrderNumberSaveState] = useState('')

    const statuses = ['active', 'complete', 'cancelled']
    const priorities = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', 
                        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',]
    const orderTypes = ['deck', 'geetee', 'wtl', 'tri-line']

    //get all inital data
    useEffect(() => {
        if(users.length === 0){
            getUsers()
        }
        if(customers.length === 0){
            getCustomers()
        }
        if(units.length === 0){
            getUnits()
        }

        firestore.collection('grainOrders').get()
        .then(async querySnapshot => {
            const response = (querySnapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  'docId': doc.id,
                }
              }))

            setPlaces(retrieveField(response, ['origin', 'destination']))
            setPer(retrieveField(response, ['ownerPer, operatorPer']))
            setMaterials(retrieveField(response, ['material']))
            setGrainOrders(response)
        })
        .catch((err) => {
            console.warn(err)
        });

        setGrainOrderLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    
    const retrieveField = (data, fieldSet) => {
        let returnContainer = []
        fieldSet.forEach(field => {
            data.forEach((doc) => { (!_.isEmpty(doc[field]) && !_.isUndefined([field])) && returnContainer.push(doc[field]) }) 
        })
        return ([...new Set(returnContainer)])
    }

    const saveData = async (grainData) => {
        setSaving(1);
        let orderContainer = _.cloneDeep(grainData)

        firestore.collection('grainOrders').add(orderContainer)
        .then(async (docRef) => {
            orderContainer.docId = docRef.id;

            await newGrainOrder({
                sandbox: process.env.NODE_ENV === 'development',
                dataArray: [orderContainer]
            }).then(() => {
                setSaving(-1)
                setTimeout(() => { 
                    setCurrentGrainOrder(initialgrainOrder);
                    setSaving(0)
                }, 1000)
            })
        })
        .catch((e) => {
            console.warn(e)
            setSaving(0)
        });
    };

    return (
        <Grid className={classes.content}>
            {grainOrderLoading || _.isUndefined(currentGrainOrder)
                ? 
                <Skeleton style={{marginTop: '40px',}} variant='rect' width={'50vw'} height={'50vh'}/>
                : 
                <GrainOrdersCreateCard
                    currentGrainOrder={currentGrainOrder} 
                    setCurrentGrainOrder={setCurrentGrainOrder}

                    initialgrainOrder={initialgrainOrder}
                    saving={saving} 
                    saveData={saveData}
                    priorities={priorities}
                    statuses={statuses}
                    users={users}
                    customers={customers}
                    units={units}
                    loading={loading}
                    usersLoading={usersLoading}
                    unitsLoading={unitsLoading}
                    grainOrders={grainOrders}
                    path={'new'}
                    per={per}
                    materials={materials}
                    places={places}
                    orderTypes={orderTypes}

                    orderNumberSaveState={orderNumberSaveState}
                    setOrderNumberSaveState={setOrderNumberSaveState}
                />
            }
        </Grid>
    )
}

export default withRouter(GrainOrdersNew)