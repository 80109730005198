import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography, TextField, Select, MenuItem, FormControl, InputLabel, CircularProgress} from '@material-ui/core';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    selectRow: {
        padding: '5px',
    },
}));

const GrainOrderPeopleAutocompletes = ({ handleSetParentValues, loading, customers, userLoading, users, docId, units, priorities, customer, operator, orderPriority, equipment, }) => {

    const classes = useStyles();

    const [autocompletes, setAutocompletes] = useState({
        operator: {open: false, value: ''},
        customer: {open: false, value: ''},
    })

    useEffect(() => {
        if(operator !== autocompletes.operator.value){ handleSetAutocompleteValue(operator, 'operator', users) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operator])

    useEffect(() => {
        if(customer !== autocompletes.customer.value){ handleSetAutocompleteValue(customer, 'customer', customers) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer])

    const handleSetAutocompleteValue = (value, field, findFrom) => {
        setAutocompletes((previousValues) => {
            let find = findFrom.find(x=> x.docId === value)
            previousValues[field].value = value !== "" && !_.isUndefined(find) ? find : value;

            return {...previousValues}
        }) 
    }
    
    return (
        <>
            <Grid container>
                <Grid item xs={4}>
                    <Autocomplete
                        open={autocompletes.customer.open}
                        onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, customer: {...previousValues.customer, open: true}} }) }}
                        onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, customer: {...previousValues.customer, open: false}} }) }}
                        loading={loading}
                        label="Customer"
                        size='small'
                        id="autocomplete"
                        autoComplete
                        autoHighlight
                        //disableClearable
                        //freeSolo={true}
                        className={classes.selectRow}
                        fullWidth
                        value={autocompletes.customer.value}
                        onChange={(event, newValue) => { 
                            let newValueContainer = newValue === null ? '' : newValue;
                            handleSetParentValues({customer: newValueContainer !== '' ? newValueContainer.docId : newValueContainer}, docId)
                            setAutocompletes((previousValues) => { return {...previousValues, customer: {...previousValues.customer, value: newValueContainer}} })
                            //handleSetCurrentGrainOrder({ ...currentGrainOrder, customer: newValue.docId }) 
                        }}
                        options={customers.sort((a, b) => a.name.localeCompare(b.name)) || {}}
                        getOptionLabel={(option) => option.name ? option.name + ' - ' + option.streetNumber + ' ' + option.streetName : ''  }
                        getOptionSelected={(option, value) =>  option.name === value?.name || {} }
                        renderOption={(option) =>( 
                            customers.filter(customer => customer.docId === option.docId).map((customerObj) => {
                                return(
                                    <Grid key={option.docId} >
                                        <Typography>{customerObj.name}</Typography>
                                        <small>{ customerObj.streetNumber + ' ' + customerObj.streetName}</small>
                                    </Grid>
                                )
                            })
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Customer"
                                variant="outlined"
                                size='small'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Autocomplete
                        open={autocompletes.operator.open}
                        onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, operator: {...previousValues.operator, open: true}} }) }}
                        onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, operator: {...previousValues.operator, open: false} }}) }}
                        loading={userLoading}
                        label="Operator"
                        size='small'
                        id="autocomplete"
                        autoComplete
                        autoHighlight
                        //disableClearable
                        //freeSolo={true}
                        className={classes.selectRow}
                        fullWidth
                        value={autocompletes.operator.value}
                        onChange={(event, newValue) => {
                            let newValueContainer = newValue === null ? '' : newValue;

                            let localGrainDataContainer = { operator: newValueContainer !== '' ? newValueContainer.docId : newValueContainer }
                            let autocompleteContainer = _.cloneDeep(autocompletes)
                            autocompleteContainer.operator.value = newValueContainer;

                            if(( !equipment && newValueContainer !== '' )){
                                let unitContainer = users.find(x=> x.docId === newValue)?.commonEquipment?.fullUnit?.docId
                                let unitAutoContiner = units.find(x=> x.docId === users.find(x=> x.docId === newValue)?.commonEquipment?.fullUnit?.docId)

                                if(!_.isUndefined(unitContainer)){  
                                    localGrainDataContainer.equipment = unitContainer 
                                    autocompleteContainer.equipment.value = unitAutoContiner;
                                }
                            }

                            setAutocompletes(autocompleteContainer)
                            handleSetParentValues(localGrainDataContainer, docId)
                            //handleSetCurrentGrainOrder(localGrainDataContainer) 
                        }}
                        options={users.filter(x=> x.employment?.active).map((user) => user ?? '').sort((a, b) => a.displayName.localeCompare(b.displayName))}
                        getOptionSelected={(option, value) => option?.docId === value?.docId || {}}
                        getOptionLabel={(option) => option.displayName || '' }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Operator"
                                variant="outlined"
                                size='small'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={4}>
                    <FormControl className={classes.selectRow} size='small' fullWidth variant='outlined'>
                    <InputLabel className={classes.selectRow}> Order Priority</InputLabel>
                        <Select
                            value={orderPriority || ''}
                            label="Order Priority"
                            onChange={(event) => {
                                handleSetParentValues({orderPriority: event.target.value}, docId)
                                //handleSetCurrentGrainOrder({...currentGrainOrder, orderPriority: event.target.value}) 
                            }}
                        >
                            {priorities.map((priority, index) => {
                                return <MenuItem key={'priority' + index} value={priority}>{priority}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>

    )
}

export default memo(GrainOrderPeopleAutocompletes)