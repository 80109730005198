import React, { useRef, useState } from 'react'
import { Typography, Grid, TableCell, TableRow, makeStyles,} from '@material-ui/core';
import _  from 'lodash';
import { DatePicker } from '@material-ui/pickers';

import DataGridTableAutocomplete from './Cells/DataGridTableAutocomplete';
import DataGridTableErrorableTextfield from './Cells/DataGridTableErrorableTextfield';

const useStyles = makeStyles((theme) => ({
    columnHeaders: {
        '&:hover': {
            textDecoration: 'none',
            color: 'grey',
          },
        textDecoration: 'none',
        color: 'black',
    },
    detailsSpacer: {
        width: '65px',
    },
    tableHeader: {
        padding: '15px',
    },
    rowContent: {
        width: '90%',
        fontSize: '14px',
        fontWeight: 600,
        // padding: '5px',
        // margin: '5px'
    },
    root: {
        userSelect: 'none',
    },
    columnTitles: {
        fontSize: '14px',
    },
}));


const DataGridTableNewRow = (props) => {

    const classes = useStyles();

    //should be set as 'defaultvalue' columns looped through fields containing empty strings
    
    const newRowValues = useRef({})
    const [rerender, setrerender] = useState(0)

    let debouncedNewRow = _.debounce(function (rowValue) {

        //if rowValue is not same as default value
        props.onNewRow([rowValue], false, props.currentFilter) 
        newRowValues.current = {}
        setrerender(rerender + 1)
    }, 1000);

    const handleNewCellChanges = (newValue, field, docId) => {
        newRowValues.current = {...props.rowValues, [field]: newValue}
        debouncedNewRow({...props.rowValues, [field]: newValue})
    }

    return (
        <>
            {/* {props.addingValues.length > 0
            ?
            <TableRow >
                <TableCell colSpan={(props.columns.filter(x=> !_.isUndefined(x.active) ? x.active : true).length + (props.action ? 2 : 1))}>
                    <Skeleton variant='rect'></Skeleton>
                </TableCell>
            </TableRow>
            : */}
            <TableRow>
                {/* details panel */}
                {/* {props.detailsPanelBool &&
                    <TableCell className={classes.detailsSpacer} />
                } */}

                {/* multiRowSelect */}
                <TableCell 
                    className={classes.multiRowSelect} 
                />
                
                {/* loop through each row */}
                {props.columns.filter(x=> !_.isUndefined(x.active) ? x.active : true).map((column, i) => {
                    return (
                        column.columnType === 'autocomplete' || column.columnType === 'select'
                            ?       
                                <TableCell key={i} >
                                    <DataGridTableAutocomplete
                                        handleCellUpdateCallback={handleNewCellChanges}

                                        //cellClickCallback={ props.cellClickCallback }
                                        disabled={props.loading}

                                        options={column.options}
                                        field={column.field}
                                        type={column.columnType}

                                        //uidCheckData={props.checkValues}
                                        uid={column.uid}

                                        key={`new ${column.field}`}
                                        id={`new ${column.field}`}

                                        data={newRowValues.current[column.field]}
                                    />
                                </TableCell>
                            :
                            column.columnType === 'date' 
                            ?
                                <TableCell key={i} >
                                    <DatePicker
                                        inputProps={{ className: classes.rowContent }}
                                        className={classes.rowContent}
                                        id={`new ${[column.field]}`}
                                        value={newRowValues.current[column.field]}
                                        format="MMMM Do YYYY"
                                        disabled={props.loading}
                                        onClick={() => {
                                            props.cellClickCallback(`new ${[column.field]}`, true) 
                                        }}
                                        onChange={(value) => { 
                                            newRowValues.current = {...props.rowValues, [column.field]: value.valueOf()} 
                                            //props.setRowValues({...props.rowValues, [column.field]: value.valueOf()})
                                            debouncedNewRow({...props.rowValues, [column.field]: value.valueOf()})
                                        }}
                                        //renderInput={(params) => <TextField {...params} />}
                                    />
                                </TableCell>
                            :
                            column.columnType === 'typography' 
                            ?
                                <TableCell key={i}>
                                    {_.isUndefined(column.uid) || column.uid
                                    ?
                                        <Typography
                                            id={`new ${[column.field]}`}
                                            className={classes.rowContent}
                                        >
                                            {'New'}
                                        </Typography>
                                    :
                                        <Typography
                                            id={`new ${[column.field]}`}
                                            className={classes.rowContent}
                                        >
                                            {props.rowValues[column.field]}
                                        </Typography>
                                    }
                                </TableCell>
                            :
                            //type = textfield
                                <TableCell key={i}>
                                    <DataGridTableErrorableTextfield 
                                        disabled={props.loading}

                                        options={column.options}
                                        field={column.field}
                                        type={column.columnType}
    
                                        uniqueTicketNumber={true}
                                        uid={column.uid}
    
                                        key={`new ${column.field}`}
                                        id={`new ${column.field}`}
    
                                        data={newRowValues.current[column.field]}

                                        handleCellUpdateCallback={handleNewCellChanges}
                                    />
                                </TableCell>
                    )  
                })}

                {/* action cell */}
                <TableCell >
                    <Grid container justifyContent='flex-end'/>
                </TableCell>

            </TableRow>
            {/* } */}
        </>
    )
}
  export default DataGridTableNewRow;