import React, { useState, useEffect } from 'react';
import { Typography, Paper, makeStyles, Grid, Button, IconButton, TextField, CircularProgress, Divider, Collapse, Checkbox, FormControlLabel, FormControl  } from '@material-ui/core';
import { withRouter } from 'react-router';
import PublishIcon from '@material-ui/icons/Publish';
import { useUser } from '../../../providers/UserContext';
import { Autocomplete } from '@material-ui/lab';
import { firestore } from '../../../firebase/firebase';
import moment from 'moment';
import { storage } from '../../../firebase/firebase';
import Skeleton from '@material-ui/lab/Skeleton';
import Dropzone from 'react-dropzone'
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIcon  from '@material-ui/icons/ArrowBack';
import { KeyboardDatePicker } from '@material-ui/pickers';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    content: {
        maxWidth: '600px',
        minWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
    paper: {
        width:'100%',
        minWidth: '600px',
        maxWidth: '600px',
        padding: theme.spacing(2),
    },
    title: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const NewDocReport = (props) => {
    const classes = useStyles();
    const { getUsers, users } = useUser();

    const [pdfList, setPdfList] = useState([])
    const [pdf, setPdf] = useState()

    const [frontImage, setFrontImage] = useState()
    const [backImage, setBackImage] = useState()
    const [submitting, setSubmitting] = useState(false)
    const [newFormName, setNewFormName] = useState('')
    const [formDate, setFormDate] = useState(new Date())

    const [driverNumber, setDriverNumber] = useState(props.employee?.driversLicenseNum || '')

    const [pdfOpenAutoComplete, setPdfOpenAutoComplete] = useState(false)
    const [pdfValueAutoComplete, setPdfValueAutoComplete] = useState('')
    const [userOpenAutoComplete, setUserOpenAutoComplete] = useState(false)
    const [userValueAutoComplete, setUserValueAutoComplete] = useState('')
    const [sendEmails, setSendEmails] = useState(true)

    let dlnprefix = !_.isUndefined(props.userData) ? props.userData.lastName.substring(0,1).toUpperCase() + props.userData.lastName.substring(1,2).toUpperCase() + "-" 
    + props.userData.lastName.substring(2,3).toUpperCase() + props.userData.lastName.substring(3,4).toUpperCase() + "-" + props.userData.lastName.substring(4,5).toUpperCase() + props.userData.firstName.substring(0,1).toUpperCase() + "-"
    : '00-00-00-'

    //grabs initial data
    useEffect(() => {
        getUsers()
        firestore.collection('pdfforms').get()
        .then(querySnapshot => {
            const formResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
            })
            setPdfList(formResponse)
        }).catch((e) => console.warn(e));
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    console.log(moment(formDate).format("YYYY-MM-DD HH:mm:ss:SSSSSS"))
    const handleFileUpload = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
          setPdf({ file, name: file.name });
        }
      };

      const handleSubmit = async () => {
        //rudimentary validation
        if (users.filter((user) => user.displayName === userValueAutoComplete).length > 1) {
          console.warn("Multiple users have the same display name, using first returned.");
        }
        console.log('submitting')

        if(props.employee && props.employee?.driversLicenseNum !== driverNumber){
            props.updateUserData({ ...props.employee, 'driversLicenseNum': driverNumber })
        }

        console.log('passed driver number')
      
        //building sumbittable form
        let formContainer = {
          pdfURL: "forms/" + pdfValueAutoComplete + "/" + users.filter((user) => user.displayName === userValueAutoComplete)[0].uuid + moment(formDate).format("YYYY-MM-DD HH:mm:ss:SSSSSS") + ".pdf",
          name: (pdfValueAutoComplete === 'New Misc. PDF File' ? newFormName : pdfValueAutoComplete),
          fileName: users.filter((user) => user.displayName === userValueAutoComplete)[0].uuid + moment(formDate).format("YYYY-MM-DD HH:mm:ss:SSSSSS") + ".pdf",
          uid: users.filter((user) => user.displayName === userValueAutoComplete)[0].uuid,
          date: formDate,
          sendEmails: sendEmails,
          jobID: "",
          timesheetID: "",
          sandbox: process.env.NODE_ENV === 'development' ? true : false,
          appearsInEmployeePage: pdfList.find((pdf) => pdf.name === pdfValueAutoComplete).appearsInEmployeePage,
        };

        console.log('built form container')
      
        //upload pdf to storage
        const storageRef = storage.ref();
        var form = storageRef.child(formContainer.pdfURL);
        console.log('storage ref')
        try {
          await form.put(pdf.file);
          const url = await form.getDownloadURL();
          formContainer = {
            ...formContainer,
            pdfURL: url,
          };
        } catch (error) {
          console.warn(error);
        }
        console.log('uploaded pdf')
        //upload doc to collection
        try {
            await firestore.collection("documents").add(formContainer);
            console.log('added to collection')
            setPdf();
            setPdfValueAutoComplete("");
            setUserValueAutoComplete("");
            setSubmitting(false);
        } catch (error) {
            console.warn(error);
        }
      };

    //substring text to use ... if too long
    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    return (
        submitting 
        ? 
        <Grid className={classes.content}>
            <Skeleton variant='rect' width={'26vw'} height={'38vh'} style={{marginTop: '80px'}}/>
        </Grid>
        :
        <Grid className={classes.content}>

            <Grid className={classes.backButton}>
                <Button variant="contained" startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.goBack()} >Back</Button>
            </Grid>

            <Paper className={classes.paper} >
                <Grid container spacing={2}>
                    <Grid item sm={12} className={classes.title}>
                        <Typography variant="h4" align="center">New Document Report</Typography>
                        
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant="h6" align="center">Upload an already filled out form to<br></br> create a Document Report for an employee</Typography>
                        <Divider variant="fullWidth" />
                        <small>Drag and drop a file or click the Upload button to add a .pdf</small>
                    </Grid>
                    {/* input button */}
                    <Grid item sm={12} className={pdf?.name ? classes.fileInputAccept : classes.fileInput}>
                  
                        <Dropzone accept={{'application/pdf': ['.pdf', '.PDF']}}
                        onDrop={handleFileUpload}>
                        {({ getRootProps, getInputProps }) => (
                            <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                <input {...getInputProps()} style={{ display: 'none' }} />
         
                                <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                <Typography variant='h6'>
                                    {pdf?.name ? substringText(pdf?.name) : "Upload Completed Form (.pdf)"}
                                </Typography>
                            
                            </Grid>
                            
                        )}
                        
                        </Dropzone>
                        {pdf?.name ?<IconButton component='span' style={{ marginLeft: '100px', color:'black' }} onClick={() => {setPdf()}}>
                            <ClearIcon />
                        </IconButton>:null}

                    </Grid>
                </Grid>
            
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {/* assign pdf based on select list of current pdfs */}
                        <Autocomplete
                            open={pdfOpenAutoComplete}
                            onOpen={() => {
                                setPdfOpenAutoComplete(true);
                            }}
                            onClose={() => {
                                setPdfOpenAutoComplete(false);
                            }}
                            label="Document"
                            id="pdfAutoComplete"
                            inputValue={pdfValueAutoComplete}
                            onInputChange={(event, newValue) => {
                                setPdfValueAutoComplete(newValue);
                                setNewFormName('');
                            }}
                            value={pdfList.name}
                            options={pdfList ? pdfList.map((pdf) => pdf.name ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase()) : [] }
                            getOptionSelected={(option, value) => option?.name === value?.name}
                            getOptionLabel={(option) => option}
                            clearOnBlur={true}
                            clearOnEscape={true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{minWidth: '225px'}}
                                    label="Which PDF Form are you uploading?"
                                    variant="outlined"
                                    size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {props.employee && pdfValueAutoComplete === 'New Misc. PDF File' ?
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <TextField
                            value={newFormName}
                            variant={'outlined'}
                            placeholder={"New Form Name"}
                            size={'small'}
                            required
                            fullWidth
                            onChange={(e)=>{
                                setNewFormName(e.target.value)
                            }}

                            />

                    </Grid>
                </Grid>:null}

                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {/* assign person based on select list of people */}
                        <Autocomplete
                            open={userOpenAutoComplete}
                            onOpen={() => {
                                setUserOpenAutoComplete(true);
                            }}
                            onClose={() => {
                                setUserOpenAutoComplete(false);
                            }}
                            label="Employee"
                            id="userAutoComplete"
                            inputValue={userValueAutoComplete}
                            onInputChange={(event, newValue) => {
                                setUserValueAutoComplete(newValue)
                            }}
                            value={props.employee ? props.employee.displayName : users.displayName}
                            options={users ? users.filter(x=> x.employment?.active).map((user) => user.displayName ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase()) : [] }
                            getOptionSelected={(option, value) => option?.displayName === value?.displayName}
                            getOptionLabel={(option) => option}
                            clearOnBlur={true}
                            clearOnEscape={true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{minWidth: '225px'}}
                                    label="Employee"
                                    variant="outlined"
                                    size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <KeyboardDatePicker
                        fullWidth
                        label="Submission Date (in case of backdated form)"
                        variant='outlined'
                        value={formDate}
                        onChange={(value)=> { 
                            setFormDate(new Date(value))
                        }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <FormControl>
                            <FormControlLabel 
                                control={ <Checkbox checked={sendEmails} onChange={(e)=>{setSendEmails(e.target.checked)}}/>}
                                label="Send Email(s) on Submission"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider style={{margin:'15px'}}/>
                <Collapse orientation="vertical" in={props.employee && pdfValueAutoComplete === 'Employee Driver Abstract'} timeout="auto" unmountOnExit>
          
                    <Grid container spacing={2} style={{border:'1px dashed grey'}}>
                        <Grid item sm={12}>
                            <Typography align="center">Not Required</Typography>  
                            <Divider style={{margin:'2px'}}/>  
                        </Grid>
                  
                        <Grid item sm={6}>
                            <Typography align="left">Driver's License #:</Typography>
                        </Grid>
                        <Grid item sm={6} style={{display:'flex', justifyContent:'flex-end'}}>
                            <TextField
                                value={driverNumber}
                                placeholder={dlnprefix + "000000"}
                                variant='outlined'
                                fullWidth
                                size="small"
                                onChange={(event) => {setDriverNumber(event.target.value) }}
                                />
                        </Grid>

                        <Grid item sm={12} className={frontImage?.name ? classes.fileInputAccept : classes.fileInput}>
                            <Dropzone accept={{'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png', '.PNG'], 'application/pdf': ['.pdf','.PDF'],}}
                            onDrop={(e)=>{props.handleFrontUpload(e);         
                                const file = e[0];
                                if (file) {
                                  setFrontImage({ file, name: file.name });
                                }}}>
                            {({ getRootProps, getInputProps }) => (
                                <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                    <input {...getInputProps()} style={{ display: 'none' }} />
            
                                    <PublishIcon style={frontImage?.name ? {color:'white', marginRight:'20px'}:{color:'grey', marginRight:'20px'}}/>

                                    <Typography variant='h6'>
                                        {frontImage?.name ? substringText(frontImage?.name) : "Upload License Front (.jpg, .png, .pdf)"}
                                    </Typography>
                                </Grid>         
                            )}
                            </Dropzone>
                        </Grid>

                        <Grid item sm={12} className={backImage?.name ? classes.fileInputAccept : classes.fileInput}>
                            <Dropzone accept={{'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png', '.PNG'], 'application/pdf': ['.pdf','.PDF'],}}
                            onDrop={(e)=>{props.handleBackUpload(e);         
                                const file = e[0];
                                if (file) {
                                  setBackImage({ file, name: file.name });
                                }}}>
                            {({ getRootProps, getInputProps }) => (
                                <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                    <input {...getInputProps()} style={{ display: 'none' }} />
            
                                    <PublishIcon style={backImage?.name ? {color:'white', marginRight:'20px'}:{color:'grey', marginRight:'20px'}}/>

                                    <Typography variant='h6'>
                                        {backImage?.name ? substringText(backImage?.name) : "Upload License Front (.jpg, .png, .pdf)"}
                                    </Typography>
                                
                                </Grid>
                                
                            )}
                            
                            </Dropzone>
                       

                        </Grid>
                    </Grid>

                    </Collapse>
                

                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {/* submit button */}
                        <Button 
                            style={{marginTop: '20px'}}
                            variant='contained'
                            disabled={(pdfValueAutoComplete === 'New Misc. PDF File' && newFormName === '')|| (!pdf?.name?.length > 0) || (!userValueAutoComplete?.length > 0) || (!pdfValueAutoComplete?.length > 0)}
                            onClick={(event) => {setSubmitting(true); handleSubmit()}}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Paper>    
                        
        </Grid>
    )
}

export default withRouter(NewDocReport);