import React, { useState } from 'react';
import {Button, Grid, Paper, TextField, Typography, makeStyles, Divider, Select, MenuItem, TableContainer, Table, TableHead, TableRow,
        TableCell, IconButton, TableBody, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Checkbox, FormControlLabel,
        FormControl, 
} from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    btnGroup:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
    },
    content: {
        maxWidth: '1000px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
    },
    paper: {
        width:'100%',
        maxWidth: '1000px',
        padding: theme.spacing(2),
    },
    title: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    text: {
        color: '#707070',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    table: {
        width: '100%',
    },
}));

const CustomerForm = (props) => {
    const classes=useStyles();

    //holds modal info
    const [deleteOpen, setDeleteOpen] = useState({open: false, index: null});
    const [contactOpen, setContactOpen] = useState({open: false, editing: false, index: null})
    //hold contact info from contact modal
    const defaultContact = {name: '', phone: '', description: '', email: '', invoiceContact: false, ccToInvoice: false}
    const [contactInfo, setContactInfo] = useState(defaultContact)

    //array of province codes only
    const provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

    const handleAddOpen = () => {
        setContactInfo(defaultContact)
        setContactOpen({open: true, editing: false, index: null});
    };

    const handleDeleteOpen = (contact, index) => {
        setDeleteOpen({open: true, index: index});
        setContactInfo(contact)
    };

    const handleEditOpen = (contact, index) => {
        setContactInfo(contact)
        setContactOpen({open: true, editing: true, index: index});
    };
    
    const handleDeleteClose = () => {
        setDeleteOpen({...deleteOpen, open: false,});
    };

    const handleContactClose = () => {
        setContactOpen({...contactOpen, open: false,});
    };


    const handleDeleteContact = () => {
        const newContacts = _.cloneDeep(props.customer.contacts)
        newContacts.splice(deleteOpen.index, 1)
        props.setCustomer({...props.customer, contacts: newContacts })

        setDeleteOpen({open: false, index: null})
        setContactInfo(defaultContact)
    }

    const handleSubmitContact = () => {
        let newContacts = _.cloneDeep(props.customer.contacts)
        if(contactInfo.invoiceContact){ newContacts = handleChangeInvoiceContact(newContacts) }
        newContacts.push(contactInfo)
        props.setCustomer({...props.customer, contacts: newContacts })

        setContactOpen({open: false, editing: false, index: null})
        setContactInfo(defaultContact)
    }

    const handleEditContact = () => {
        let newContacts = _.cloneDeep(props.customer.contacts)
        if(contactInfo.invoiceContact){ newContacts = handleChangeInvoiceContact(newContacts) }
        newContacts.splice(contactOpen.index, 1, contactInfo)
        props.setCustomer({...props.customer, contacts: newContacts})

        setContactOpen({open: false, editing: false, index: null})
        setContactInfo(defaultContact)
    }

    const handleChangeInvoiceContact = (contacts) => {
        contacts.forEach((contact) => {
            if(contact.invoiceContact){ contact.invoiceContact = false; }
        })
        return contacts
    }

    return (
        <Grid className={classes.content}>
            {/* delete contact modal */}
            <Dialog open={deleteOpen.open} onClose={handleDeleteClose}>
                <DialogTitle>Delete Contact</DialogTitle>
                <DialogContent>
                <Typography>Are you sure you want to delete contact: {contactInfo.name}?</Typography>
                    <DialogContentText>
                        This is NOT reversable!
                    </DialogContentText>
                    
                    <DialogActions>
                        <Button variant="contained" color="secondary" size="small" onClick={handleDeleteClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" size="small" onClick={() => { handleDeleteContact() }}>
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            {/* contact modal */}
            <Dialog open={contactOpen.open} onClose={handleContactClose}>
                <DialogTitle>{!contactOpen.editing ? "Add" : "Edit"} Contact</DialogTitle>
                <DialogContent>
                <DialogContentText>
                {!contactOpen.editing ? "Please enter the details of the new contact." : "Edit the current contact."}
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    value={contactInfo.name}
                    error={contactInfo.name === ''}
                    helperText={contactInfo.name === '' ? "Contact requires a name": ''}
                    onChange={(event) => { setContactInfo({ ...contactInfo, 'name': event.target.value }) }}
                />
                <Grid container spacing={2}> 
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="phone"
                            label="Phone #"
                            value={contactInfo.phone}
                            type="tel"
                            onChange={(event) => { setContactInfo({ ...contactInfo, 'phone': event.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="phonedesc"
                            label="Phone Description"
                            value={contactInfo.description}
                            type="tel"
                            onChange={(event) => { setContactInfo({ ...contactInfo, 'description': event.target.value }) }}
                        />
                    </Grid>       
                </Grid>
                <TextField
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    value={contactInfo.email}
                    fullWidth
                    onChange={(event) => { setContactInfo({ ...contactInfo, 'email': event.target.value }) }}
                />

                <Grid container spacing={2}> 
                    <Grid item xs={6}>
                        <FormControl>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={contactInfo.invoiceContact}
                                        onChange={(event) => { setContactInfo({ ...contactInfo, 'invoiceContact': event.target.checked }) }}
                                    />
                                } 
                                label="Invoice Contact" 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={contactInfo.ccToInvoice}
                                        onChange={(event) => { setContactInfo({ ...contactInfo, 'ccToInvoice': event.target.checked }) }}
                                    />
                                } 
                                label="CC to Invoice" 
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="secondary" size="small" onClick={handleContactClose}>
                    Cancel
                </Button>
                <Button 
                    disabled={contactInfo.name === ''} 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    onClick={!contactOpen.editing ? handleSubmitContact : handleEditContact}>
                    Submit
                </Button>
                </DialogActions>
            </Dialog>

            <Grid className={classes.backButton}>
                <Button variant="contained" startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.goBack()} >Back</Button>
            </Grid>

            <form onSubmit={props.handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.title}>
                            <PersonAddIcon style={{marginRight:'8px'}}/>
                            <Typography variant="h4" align="center">New Customer</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Name*</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="name" value={props.customer.name} onChange={props.textHandler}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Job Number</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="jobNumber" value={props.customer.jobNumber} onChange={props.textHandler}/>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography>Email</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="email" value={props.customer.email.value} onChange={props.textHandler}/>
                        </Grid> */}

                        {/* contacts area */}
                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            Contacts
                            <Divider />
                        </Grid>

                        <Grid container>
                            <TableContainer>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Contact</TableCell>
                                            <TableCell align="right">Phone Number</TableCell>
                                            <TableCell align="right">Phone Description</TableCell>
                                            <TableCell align="right">Email Address</TableCell>
                                            <TableCell align="right">Invoice Contact</TableCell>
                                            <TableCell align="right">CC to Invoice</TableCell>
                                            <TableCell align="center">Delete</TableCell>
                                            <TableCell align="center">Edit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    
                                    <TableBody>
                                    {props.customer.contacts.map((contact, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {contact.name}
                                            </TableCell>
                                            <TableCell align="right">{contact.phone}</TableCell>
                                            <TableCell align="right">{contact.description}</TableCell>
                                            <TableCell align="right">{contact.email}</TableCell>
                                            <TableCell align="center">
                                                <IconButton disabled color="secondary">{ contact.invoiceContact ? <CheckIcon/> : <CloseIcon/> }</IconButton>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton disabled color="secondary">{ contact.ccToInvoice ? <CheckIcon/> : <CloseIcon/> }</IconButton>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => handleDeleteOpen(contact, index)} color="secondary"><DeleteForeverIcon/></IconButton>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => handleEditOpen(contact, index)} color="secondary"><EditIcon/></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid item xs={6} style={{margin:'16px'}}>
                                <Button variant="contained" onClick={() => handleAddOpen() } startIcon={<AddIcon/>}>Add Contact</Button>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            Mailing Address
                            <Divider />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>Street Number</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="streetNumber" value={props.customer.streetNumber} onChange={props.textHandler}/>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>Street Name</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="streetName" value={props.customer.streetName} onChange={props.textHandler}/>
                        </Grid>

                        <Grid item xs={5}>
                            <Typography>City</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="city" value={props.customer.city} onChange={props.textHandler}/>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>Province</Typography>
                            <Select
                                variant="outlined" 
                                fullWidth 
                                size="small"
                                value={props.customer.province}
                                onChange={(event) => { props.textHandler(event) }}
                                name="province"
                            >
                                {provinces.map((province) => (
                                    <MenuItem key={province} value={province}>
                                        {province}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Postal Code</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="postalCode" value={props.customer.postalCode} onChange={props.textHandler}/>
                        </Grid>


                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            Delivery Address
                            <Divider />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>Street Number</Typography>
                            <TextField 
                                size="small" 
                                fullWidth 
                                variant='outlined' 
                                name="streetNumber" 
                                value={props.customer.deliveryAddress.streetNumber} 
                                onChange={(event) => { props.textHandler(event, 'deliveryAddress') }}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>Street Name</Typography>
                            <TextField 
                                size="small" 
                                fullWidth 
                                variant='outlined' 
                                name="streetName" 
                                value={props.customer.deliveryAddress.streetName} 
                                onChange={(event) => { props.textHandler(event, 'deliveryAddress') }}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <Typography>City</Typography>
                            <TextField 
                                size="small" 
                                fullWidth 
                                variant='outlined' 
                                name="city" 
                                value={props.customer.deliveryAddress.city} 
                                onChange={(event) => { props.textHandler(event, 'deliveryAddress') }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>Province</Typography>
                            <Select
                                variant="outlined" 
                                fullWidth 
                                size="small"
                                value={props.customer.deliveryAddress.province}
                                onChange={(event) => { props.textHandler(event, 'deliveryAddress') }}
                                name="province"
                            >
                                {provinces.map((province) => (
                                    <MenuItem key={province} value={province}>
                                        {province}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Postal Code</Typography>
                            <TextField 
                                size="small" 
                                fullWidth 
                                variant='outlined' 
                                name="postalCode" 
                                value={props.customer.deliveryAddress.postalCode} 
                                onChange={(event) => { props.textHandler(event, 'deliveryAddress') }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography align="center" style={{color:'red'}}>{props.error}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" size="large" disabled={props.submitting} type="submit">{props.submitting ? "Submitting..." : "Create Customer"}</Button>
                        </Grid>
                        <Grid item xs={12}>
                            {props.success && <Typography align="center" style={{color:'green'}}>Customer Created Successfully</Typography>}
                            {props.error !== '' ? <Typography align="center" style={{color:'red'}}>Error Creating Customer: {props.error}</Typography> : null}
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    )
}

export default withRouter(CustomerForm);