import React, { useState, useEffect } from 'react';
import moment from 'moment'
import MaterialTable, {MTableToolbar} from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { firestore } from '../../../../firebase/firebase'
import TimesheetOptions from '../../../components/Reports/Timesheets/TimesheetOptions'
import { useAuth } from '../../../../providers/AuthContext';
import { Divider, IconButton, Paper, Typography, useMediaQuery, Button, } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
//not used, users should be loaded by now from reporting component
import _ from 'lodash'
import clsx from 'clsx';
import ReportSelect from '../../../components/Reports/ReportSelect'
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../../providers/UserContext';
import { useTheme } from '@material-ui/core/styles';
//import { useTimesheetType } from '../../../../providers/TimesheetTypeContext';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import { useAuth } from '../../../../providers/AuthContext';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Skeleton } from '@material-ui/lab';
import { useTimesheetType } from '../../../../providers/TimesheetTypeContext';

import CreateNewTimesheetModal from '../../../components/Reports/Timesheets/Modals/CreateNewTimesheetModal';

/* eslint no-mixed-operators: 0 */  // --> OFF

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
    sidebarSpacer: {
        marginLeft: '32px',
        maxWidth: '256px',
        width: '100%',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
    modal: {
        width: '500px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '40vh',
    },
}));

const TimesheetReport = (props) => {
    const { users, getUsers, userLoading } = useUser();
    const { userSettings } = useAuth();
    const darkMode = !_.isUndefined(userSettings['colorMode']) ? userSettings['colorMode'] : 'light';
    const { timesheetType } = useTimesheetType();
    const classes = useStyles();
    const theme = useTheme();
    //const { roles } = useAuth();
    //const { timesheetType } = useTimesheetType();
    const [selecting, setSelecting] = useState(JSON.parse(sessionStorage.getItem('timesheetSelecting')) || false);
    const [searchState, setSearch] = useState({
        type: JSON.parse(sessionStorage.getItem("timesheetOptionsSearchType")) || 'employee',
        value: JSON.parse(sessionStorage.getItem("timesheetOptionsSearchValue")) || '',
    });
    const [createNewModal, setCreateNewModal] = useState(false)
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))).utc() : moment().startOf('day').utc());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))).utc() : moment().endOf('day').utc());
    //Search Autocomplete Values
    const [autoCompleteValue, setAutoCompleteValue] =  useState("timesheetAutoComplete" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetAutoComplete")) : "")
    const [typeAutoCompleteValue, setTypeAutoCompleteValue] =  useState("timesheetTypeAutoComplete" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetTypeAutoComplete")) : "")
    const [timesheetData, setTimesheetData] = useState(JSON.parse(sessionStorage.getItem("timesheetData")) || []);
    const [filteredData, setFilteredData] = useState(JSON.parse(sessionStorage.getItem("filteredData")) || []);
    const [unitData, setUnitData] = useState(JSON.parse(sessionStorage.getItem("timesheetUnitData1")+sessionStorage.getItem("timesheetUnitData2")+sessionStorage.getItem("timesheetUnitData3")) || []);
    const [jobData, setJobData] = useState(JSON.parse(sessionStorage.getItem("timesheetJobData1")+sessionStorage.getItem("timesheetJobData2")+sessionStorage.getItem("timesheetJobData3")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [searchBarValue] = useState("timesheetSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetSearchBarValue")) : null)
    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    const [checkboxState, setChecked] = React.useState({
        unsubmitted: "timesheetOptionsUnsubmitted" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetOptionsUnsubmitted")) : false,
        approved: "timesheetOptionsApproved" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetOptionsApproved")) : false,
        submitted: "timesheetOptionsSubmitted" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetOptionsSubmitted")) : true,
        unseen: "timesheetOptionsUnseen" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetOptionsUnseen")) : true,
        viewed: "timesheetOptionsViewed" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetOptionsViewed")) : true,
        printed: "timesheetOptionsPrinted" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetOptionsPrinted")) : true,
    });

    //users should already be loaded in auth context
    useEffect(() => {
        if(_.isUndefined(users)){
            getUsers();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
     
    useEffect(() => {
        if (!timesheetData) return;
    
        let expandedTimesheets = [];
    
        // Include unsubmitted timesheets
        if (checkboxState.unsubmitted) {
            expandedTimesheets = [
                ...expandedTimesheets,
                ...timesheetData.filter(timesheet => !timesheet.submitted),
            ];
        }
    
        if (checkboxState.submitted) {
            expandedTimesheets = [
                ...expandedTimesheets,
                ...timesheetData.filter(timesheet => timesheet.submitted && !timesheet.approved),
            ];
        }
    

        // Include approved timesheets
        if (checkboxState.approved) {
            expandedTimesheets = [
                ...expandedTimesheets,
                ...timesheetData.filter(timesheet => timesheet.approved && !timesheet.payrolled),
            ];
        }
    
        // Include payrolled timesheets
        if (checkboxState.payrolled) {
            expandedTimesheets = [
                ...expandedTimesheets,
                ...timesheetData.filter(timesheet => timesheet.payrolled),
            ];
        }
    
        // Remove duplicates from the expanded list
        expandedTimesheets = Array.from(new Set(expandedTimesheets));
    
        // Apply typed value filter (exclusion filter)
        if (typeAutoCompleteValue) {
            expandedTimesheets = expandedTimesheets.filter(data => data.timesheetType === typeAutoCompleteValue);
        }
    
        // Apply printed checkbox filter (exclusion filter)
        if (checkboxState.printed) {
            expandedTimesheets = expandedTimesheets.filter(data => data.timesheetStatus === 'printed');
        }
    
        // Update state and session storage
        setFilteredData(expandedTimesheets);
        sessionStorage.setItem("filteredData", JSON.stringify(expandedTimesheets));
    
        // eslint-disable-next-line
    }, [checkboxState, timesheetData, typeAutoCompleteValue]);
    
    
    
    useEffect(() => {
    }, [smallScreen, selecting]);

    /*
    const [searchState, setSearch] = useState({
        type: 'timesheetID',
        value: '',
    }); */

    const handleExpandClick = () => {
        if (expanded)
            setExpanded(false);
        else
            setExpanded(true);
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };

    const handlePrint = () => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/printing/table/',
            state: { data: mergeData().filter((timesheet) => (timesheet.approved === true && timesheet.timesheetID !== 'Unsubmitted') || (timesheet.approved === false && timesheet.timesheetID === 'Unsubmitted')).sort((a, b) => a.timesheetID > b.timesheetID), 
            timesheetData: mergeData().filter((timesheet) => (timesheet.approved === true && timesheet.timesheetID !== 'Unsubmitted') || (timesheet.approved === false && timesheet.timesheetID === 'Unsubmitted')).sort((a, b) => a.timesheetID > b.timesheetID), totalHours: hours,
        firstDate: moment(startDate).format('MMM DD, YYYY'), lastDate: moment(endDate).format('MMM DD, YYYY') }
        })
    }

    const handlePrintPayroll = () => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/printing/payroll/',
            state: { data: mergeData().filter((timesheet) => (timesheet.approved === true && timesheet.timesheetID !== 'Unsubmitted') || (timesheet.approved === false && timesheet.timesheetID === 'Unsubmitted')).sort((a, b) => a.timesheetID > b.timesheetID), 
            timesheetData: mergeData().filter((timesheet) => (timesheet.approved === true && timesheet.timesheetID !== 'Unsubmitted') || (timesheet.approved === false && timesheet.timesheetID === 'Unsubmitted')).sort((a, b) => a.timesheetID > b.timesheetID), totalHours: hours,
        firstDate: moment(startDate).format('MMM DD, YYYY'), lastDate: moment(endDate).format('MMM DD, YYYY') }
        })
    }
    
    const handlePrintSelected = () => {
        
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/printing/selected/',
            state: { data: selectedData.sort((a, b) => a.timesheetID > b.timesheetID), timesheetData: selectedData.sort((a, b) => a.timesheetID > b.timesheetID), totalHours: hours }
        })
    }

    const handlePrintTotal = () => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/printing/total/',
            state: { data: mergeData().filter((timesheet) => (timesheet.approved === true && timesheet.timesheetID !== 'Unsubmitted') || (timesheet.approved === false && timesheet.timesheetID === 'Unsubmitted')), timesheetData: timesheetData, totalHours: hours, startDate: moment(startDate).format('MMM DD, YYYY'), endDate: moment(endDate).format('MMM DD, YYYY') }
        })
    }

    const searchHandler = (event) => {
        setSearch({ ...searchState, [event.target.name]: event.target.value })
        if(event.target.name === "type") sessionStorage.setItem("timesheetOptionsSearchType", JSON.stringify(event.target.value));
        if(event.target.name === "value") sessionStorage.setItem("timesheetOptionsSearchValue", JSON.stringify(event.target.value))

        //changing to other option clears previous
        if(event.target.value === "employee") sessionStorage.removeItem("timesheetOptionsSearchValue", setSearch({value: ''}))
        if(event.target.value === "timesheetID") sessionStorage.removeItem("timesheetAutoComplete", setAutoCompleteValue(''))
    }

    const clearSearch = () => {
        setSearch({ ...searchState, value: '' });
        sessionStorage.removeItem("timesheetOptionsSearchValue");
    }

    const checkboxHandler = (event) => {
        setChecked({ ...checkboxState, [event.target.name]: event.target.checked });
        if(event.target.name === "unsubmitted") sessionStorage.setItem("timesheetOptionsUnsubmitted", JSON.stringify(event.target.checked))
        else if(event.target.name === "approved") sessionStorage.setItem("timesheetOptionsApproved", JSON.stringify(event.target.checked))
        else if(event.target.name === "printed") sessionStorage.setItem("timesheetOptionsPrinted", JSON.stringify(event.target.checked))
        else if(event.target.name === "viewed") sessionStorage.setItem("timesheetOptionsViewed", JSON.stringify(event.target.checked))
        else if(event.target.name === "unseen") sessionStorage.setItem("timesheetOptionsUnseen", JSON.stringify(event.target.checked))
    };

    const startDateHandler = date => {
        setStartDate(date);
        sessionStorage.setItem("startDate", JSON.stringify(date));
    }
    const endDateHandler = date => {
        setEndDate(date);
        sessionStorage.setItem("endDate", JSON.stringify(date));
    }

    const timesheetClickHandler = path => {
        props.timesheetClickHandler(path);
    }

    async function dateCompat() {
        //const selectedParams = ['']
        let query = firestore.collection('timesheets')

        /*
        if (!checkboxState.unsubmitted) query = query.where('submitted', '==', true);
        if (checkboxState.approved) query = query.where('approved', '==', true);
        if (checkboxState.unseen) selectedParams.push('unseen');
        if (checkboxState.viewed) selectedParams.push('viewed');
        if (checkboxState.printed) selectedParams.push('printed');
        if (!checkboxState.unseen && !checkboxState.viewed && !checkboxState.printed) {
            selectedParams.push('unseen');
            selectedParams.push('viewed');
            selectedParams.push('printed');
        }

        query = query.where('timesheetStatus', 'in', selectedParams); */

        let uid = users.find(user => user.displayName === autoCompleteValue)?.uuid
        if (uid) query = query.where('uid', '==', uid)

        if( typeAutoCompleteValue !== null && !_.isUndefined(typeAutoCompleteValue) && typeAutoCompleteValue !== "" ){
            query = query.where('timesheetType', '==', typeAutoCompleteValue)
        }
    
        query.orderBy('date')

        const oldDate = query.where("date", ">=", startDate.startOf('day').unix()).where("date", "<=", endDate.endOf('day').unix()).get()
        const newDate = query.where("date", ">=", startDate.startOf('day').utc().valueOf()).where("date", "<=", endDate.endOf('day').utc().valueOf()).get()

        const [oldDateSnapshot, newDateSnapshot] = await Promise.all([newDate, oldDate]).catch((err) => console.log(err))

        const oldDateArray = oldDateSnapshot.docs
        const newDateArray = newDateSnapshot.docs

        return _.concat(oldDateArray, newDateArray)
    }

    const generateHandler = () => {
        sessionStorage.removeItem("timesheetData");
        sessionStorage.removeItem("timesheetUnitData1");
        sessionStorage.removeItem("timesheetUnitData2");
        sessionStorage.removeItem("jobData");
        sessionStorage.removeItem("defectData");
	    sessionStorage.removeItem("unitData");
        sessionStorage.removeItem("defectData");
        sessionStorage.removeItem("deliveryData");
      	sessionStorage.removeItem("deliveryUnitData");
        sessionStorage.removeItem("covidData");
       	sessionStorage.removeItem("checklistData");
        sessionStorage.removeItem("photoData");
        sessionStorage.removeItem("filteredPhotoData");
        sessionStorage.removeItem("REACT_COOL_IMG");
        sessionStorage.removeItem("filteredOrderData");

        setErrorMessage(null);
        if(endDate.endOf('day').isBefore(startDate.startOf('day')) ){
            setErrorMessage('Start Date later than End Date!')
            return
        }
        if(endDate.diff(startDate, 'd') > 365){
            setErrorMessage('Query cannot be more than a one year period.')
            return
        }
        else{
            setErrorMessage('')
        }

        setLoading(true);

        dateCompat().then(querySnapshot => {
           /* const promises = [];
            //process & store timesheet data

            querySnapshot.forEach(doc => {
                if(_.isUndefined(doc.data().approved)){
                    promises.push(doc.ref.update({
                        approved: doc.data().approved ? true : false,
                    }));
                }
                
            });
            return Promise.all(promises).then(() =>{*/

            let timesheetResponse = querySnapshot.map((doc) => {

                let date = doc.data().date
                let inTime = doc.data().usingGateInTime ? doc.data().gateIn15 : doc.data().inTime
                let outTime = doc.data().usingGateOutTime ? doc.data().gateOut15 : doc.data().outTime
                if (inTime < 9999999999) inTime *= 1000
                if (outTime < 9999999999) outTime *= 1000
                if (date < 9999999999) date *= 1000
                var oHours = outTime - inTime
                oHours -= ((doc.data().skipLunch === "na" || doc.data().skipLunch === 'declined') ? 1800000 : 0)
                oHours = oHours / 3600000;
                oHours -= doc.data().offHours;
                oHours = Math.max(oHours.toFixed(2), 0);
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'approved': doc.data().approved !== null ? doc.data().approved : false,
                    'date': moment.utc(date).format('YYYY-MM-DD'),
                    'timesheetID': doc.data().timesheetID === 0 ? 'Unsubmitted' : doc.data().timesheetID,
                    'operatorHours': oHours.toFixed(2),
                }
            })

             if (searchState.value !== "") {
                switch (searchState.type) {
                    case 'timesheetID':
                        timesheetResponse = timesheetResponse.filter(timesheet => timesheet.timesheetID.toString().includes(searchState.value));
                        break;
                        /*
                    case 'employee': 
                        //Get valid users
                        let match = users.filter(user => user?.displayName && user?.uuid);
                        //Search users with displayName containing search value
                        match = match.filter(user => user.displayName.toLowerCase().includes(searchState.value.toLowerCase()));
                        //Get uuids of users
                        match = match.map(user => { return user.uuid });
                        //Compare timesheet uuids to uuids of match
                        timesheetResponse = timesheetResponse.filter(timesheet => match.includes(timesheet.uid))
                        break;
                        */
                    default:
                        break;
                }
             }

            setTimesheetData(timesheetResponse)
            sessionStorage.setItem("timesheetData", JSON.stringify(timesheetResponse))

            //query for unit data based on timesheet doc ids
            //const timesheetDocIds = timesheetData.map(ts => ts.docId);

            //or dates?
            return firestore.collection('units').where("date", ">=", startDate.startOf('day').valueOf()).where("date", "<=", endDate.endOf('day').valueOf()).get();

        }).then((querySnapshot) => {
            const unitResponse = querySnapshot.docs.map((doc) => {
                return { 'docId': doc.id,
                    'unitNumber': doc.data()?.unitNumber,
                    'mileageOut': doc.data()?.mileageOut,
                    'mileageIn': doc.data()?.mileageIn,
                    'fuelAdded': doc.data()?.fuelAdded,
                    'fuelUnitDisplay': doc.data()?.fuelUnitDisplay,
                    //'jobTimes': doc.data()?.jobTimes,
                    'timesheetID': doc.data()?.timesheetID,
                    'unit': doc.data()?.unit,
             }
            });
            // Convert the data to JSON string
            var unitResponseString = JSON.stringify(unitResponse);
            // Determine the indices for splitting into six parts
            var firstSplitIndex = Math.ceil(unitResponseString.length / 6);
            var secondSplitIndex = 2 * firstSplitIndex;
            var thirdSplitIndex = 3 * firstSplitIndex;
            var fourthSplitIndex = 4 * firstSplitIndex;
            var fifthSplitIndex = 5 * firstSplitIndex;

            // Split the string into two parts
            var part1 = unitResponseString.substring(0, firstSplitIndex);
            var part2 = unitResponseString.substring(firstSplitIndex, secondSplitIndex);
            var part3 = unitResponseString.substring(secondSplitIndex, thirdSplitIndex);
            var part4 = unitResponseString.substring(thirdSplitIndex, fourthSplitIndex);
            var part5 = unitResponseString.substring(fourthSplitIndex, fifthSplitIndex);
            var part6 = unitResponseString.substring(fifthSplitIndex);

            // Store each part in sessionStorage
            sessionStorage.setItem("timesheetUnitDataPart1", part1);
            sessionStorage.setItem("timesheetUnitDataPart2", part2);
            sessionStorage.setItem("timesheetUnitDataPart3", part3);
            sessionStorage.setItem("timesheetUnitDataPart4", part4);
            sessionStorage.setItem("timesheetUnitDataPart5", part5);
            sessionStorage.setItem("timesheetUnitDataPart6", part6);
            var originalData = JSON.parse(part1 + part2 + part3 + part4 + part5 + part6);
            setUnitData(originalData);
            //sessionStorage.setItem("timesheetUnitData", JSON.stringify(unitResponse))
        }).then(() => {
            firestore.collection('jobs').where("date", ">=", startDate.startOf('day').valueOf()).where("date", "<=", endDate.endOf('day').valueOf()).get()
                .then((querySnapshot) => {
                    const jobResponse = querySnapshot.docs.map((doc) => {
                        return { 'docId': doc.id,
                            'timesheetID': doc.data()?.timesheetID,
                    }
                    });
                    // Convert the data to JSON string
                    var jobResponseString = JSON.stringify(jobResponse);

                    // Determine the indices for splitting into three parts
                    var firstSplitIndex = Math.ceil(jobResponseString.length / 3);
                    var secondSplitIndex = 2 * firstSplitIndex;

                    // Split the string into two parts
                    var part1 = jobResponseString.substring(0, firstSplitIndex);
                    var part2 = jobResponseString.substring(firstSplitIndex, secondSplitIndex);
                    var part3 = jobResponseString.substring(secondSplitIndex);

                    // Store each part in sessionStorage
                    sessionStorage.setItem("timesheetJobDataPart1", part1);
                    sessionStorage.setItem("timesheetJobDataPart2", part2);
                    sessionStorage.setItem("timesheetJobDataPart3", part3);
                    var originalData = JSON.parse(part1 + part2 + part3);
                    setJobData(originalData);
                    setLoading(false);
                })


        }).catch(e => {
            setLoading(false);
            
            switch(e.name){
                case 'QuotaExceededError':
                    setErrorMessage("Query size too big for browser storage.");
                    console.log(e.message);
                    break;
                default:
                    setErrorMessage("Unable to process.");
                    console.log(e.message);
            }
        });
    
    }

    function buildUnits(units) {
        return units.unit.map((uni, index) => <Typography key={index}><small><b>{uni}</b></small></Typography>)
    }
    function buildMileage(miles) {
        return miles.map((mile, index) => <Typography key={index}>{mile}</Typography>)
    }
    function buildFuel(fuelAdded) {
        return fuelAdded.map((fuel, index) => <Typography key={index}>{fuel}</Typography>)
    }

    //merges unit and 
    function mergeData() {
        return filteredData.map(x => {
            if (x.outTime < 9999999999) x.outTime *= 1000
            if (x.inTime < 9999999999) x.inTime *= 1000
            const user = users.find((user) => user.uuid === x.uid)
            x.uid = _.isUndefined(user) ? x.uid : user.displayName;
            if(x.timesheetID === 'Unsubmitted') {x.status = ''} else x.status = 'Submitted'
            if(x.approved && x.payrolled) x.status = 'Payrolled'
            else if(x.approved && !x.payrolled) x.status = 'Approved'
            if( x.timesheetStatus === 'printed') x.status = x.status+' (Printed)'
            x.department = _.isUndefined(user) ? x.department : user.department;
            const units = unitData.filter(unit => unit.timesheetID === x.docId);
            x.opHours = ((x.outTime - x.inTime - (((x.skipLunch === 'na' || x.skipLunch === 'declined') ? 1800000 : 0))) / 3600000) - x.offHours
            x.opHours = Math.max(x.opHours.toFixed(2), 0);

            const jobs = jobData.filter(job => job.timesheetID === x.docId);

            return {
                ...x,
                unit: units.map(unit => unit.unitNumber),
                job: jobs.map(job => job),
                mileage: units.map(unit => (parseFloat(unit.mileageOut) - parseFloat(unit.mileageIn)).toFixed(2)),
                mileageIn: units.map(unit => (parseFloat(unit.mileageIn)).toFixed(2)),
                mileageOut: units.map(unit => (parseFloat(unit.mileageOut)).toFixed(2)),
                fuelAdded: units.map(unit => unit.fuelAdded ? unit.fuelAdded + unit.fuelUnitDisplay : ''),
                //jobTimes: units jobTimes with the unitNumber added to the unit.jobTimes map
                
                //remove the parent array of jobTimes
                jobTimes: units.map(unit => unit.jobTimes ? unit.jobTimes.map(jobTime => { return { ...jobTime, 'unitNumber': unit.unitNumber } }): [] ).flat(),
                
            }
            
        });
    }

    //used to show values on bottom of screen, could be calulated on generation of data
    //function calcViewed() {
    //    return timesheetData.filter((timesheet) => timesheet.timesheetStatus === 'viewed').length
    //}
    function calcPrinted() {
        return timesheetData.filter((timesheet) => timesheet.timesheetStatus === 'printed').length
    }
    //function calcUnseen() {
     //   return timesheetData.filter((timesheet) => timesheet.timesheetStatus === 'unseen').length
    //}
    function calcUnsubmitted() {
        return timesheetData.filter((timesheet) => timesheet.timesheetID === 'Unsubmitted').length
    }
    function calcApproved() {
        return timesheetData.filter((timesheet) => timesheet.approved === true).length
    }
    function calcUnapproved() {
        return timesheetData.filter((timesheet) => timesheet.approved === false && timesheet.timesheetID !== 'Unsubmitted').length
    }
    function calcPayrolled() {
        return timesheetData.filter((timesheet) => timesheet.payrolled === true).length
    }
    function calcHours() {
        return mergeData().filter((timesheet) => (timesheet.approved === true && timesheet.timesheetID !== 'Unsubmitted') || (timesheet.approved === false && timesheet.timesheetID === 'Unsubmitted')).map(x => {
            if (x.outTime < 9999999999) x.outTime *= 1000
            if (x.inTime < 9999999999) x.inTime *= 1000
            x.opHours = (((x.usingGateOutTime ? x.gateOut15 : x.outTime) - (x.usingGateInTime ? x.gateIn15 : x.inTime) - (((x.skipLunch === 'na' || x.skipLunch === 'declined') ? 1800000 : 0))) / 3600000) - x.offHours
            x.opHours = Math.max(x.opHours.toFixed(2), 0);
            return { time: x.opHours }
        }).reduce((total, timesheet) => total + timesheet.time, 0).toFixed(2)
    }

    const hours = calcHours();
    //const viewedSheets = calcViewed();
    const printedSheets = calcPrinted();
    //const unseenSheets = calcUnseen();
    const unsubmittedSheets = calcUnsubmitted();
    const unapprovedSheets = calcUnapproved();
    const approvedSheets = calcApproved();
    const payrolledSheets = calcPayrolled();

    let selectedData = []

    const onCreateTimesheet = (newDoc) => {
        let container = [...timesheetData, newDoc]
        setTimesheetData(container)
        sessionStorage.setItem("timesheetData", JSON.stringify(container));
    }

    const onCloseCallback = () => {
        setCreateNewModal(false)
    }
  
    return (
        <React.Fragment>
            <CreateNewTimesheetModal 
                open={createNewModal}
                onCreateCallback={onCreateTimesheet}
                onCloseCallback={onCloseCallback}
                //not called within because places should theoretically have these
                timesheetType={timesheetType}
                users={users}
                usersLoading={userLoading}
            />
            <div className={classes.content}
                style={expanded ? {paddingRight: '260px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >
                    {loading === false ?
                    <MaterialTable
                        columns={
                            [
                            { title: 'Timesheet', field: 'timesheetID', defaultSort: 'desc', render:
                                rowData => rowData.status === undefined ? <Typography>{rowData.timesheetID === 0 ? 'Unsubmitted' : rowData.timesheetID}</Typography>
                                :  <Typography>{rowData.timesheetID}<small>{'\n'+ rowData.status}</small></Typography>
                             },
                            { title: 'Employee', field: 'uid', },
                            { title: 'Timesheet Type', field: 'timesheetType', render: rowData => rowData.timesheetType || rowData.timesheetType === "" ? rowData.timesheetType : "NA"},
                            { title: 'Date', field: 'date', },
                            { title: 'Start Time', render: rowData => rowData.usingGateInTime ? moment(rowData.gateInTime).format('hh:mm A') : moment(rowData.inTime).format('hh:mm A') },
                            { title: 'End Time', render: rowData => rowData.usingGateOutTime ? moment(rowData.gateOutTime).format('hh:mm A') :moment(rowData.outTime).format('hh:mm A') },
                            { title: 'Hours', field: 'operatorHours' , },
                            { title: 'Units', field: 'unit',  render: rowData => buildUnits(rowData) },
                            { title: 'Mileage In', field: 'mileage',  render: rowData => buildMileage(rowData.mileageIn) },
                            { title: 'Mileage Out', field: 'mileage',  render: rowData => buildMileage(rowData.mileageOut) },
                            { title: 'Total Mileage', field: 'mileage',  render: rowData => buildMileage(rowData.mileage) },
                            { title: 'Fuel', field: 'fuelAdded',  render: rowData => buildFuel(rowData.fuelAdded) },
                            
                        ]}
                        data={mergeData()}
                        title={`Timesheets - Total Hours: ${hours}`}
                        icons={TableIcons}
                        isLoading={loading}
                        onSearchChange={ (value) => { sessionStorage.setItem("timesheetSearchBarValue", JSON.stringify(value))}}
                        onRowsPerPageChange={(num) => {sessionStorage.setItem("timesheetReportRowsPerPage", JSON.parse(num))}}
                        actions={[
                            {
                                icon: TableIcons.OpenInNew,
                                tooltip: 'View Timesheet',
                                onClick: (event, rowData) => timesheetClickHandler(rowData['docId']),
                            },
                        ]}
                        onRowClick={(event, rowData, togglePanel) => {
                            if (!selecting && (rowData?.notes !== "" || (rowData.photoURLs && rowData?.photoURLs?.length > 0) || (rowData.checklist && rowData?.checklist?.length > 0)) ) {
                                togglePanel();
                            } else {
                                return null;
                            }
                        }}
                        onSelectionChange={(rows) =>
                            {selectedData = rows
                                //setSelectedSheets(rows)
                                
                            //console.log(selectedData)
                            }
                        }
                        
                        detailPanel={[
                            rowData => ({
                                disabled: !selecting && (rowData?.notes || rowData?.photoURLs?.length > 0 || rowData?.checklist?.length > 0) ? false : true,
                                tooltip: !selecting && (rowData?.notes || rowData?.photoURLs?.length > 0 || rowData?.checklist?.length > 0) ? 'Show Details': null,
                                icon: () => !selecting && (rowData?.notes || rowData?.photoURLs?.length > 0 || rowData?.checklist?.length > 0) ? <small><ArrowForwardIosIcon style={{ fontSize: '15px' }} /></small> : null,
                                //openIcon: () => rowData.notes ? <CommentOutlinedIcon /> : null,
                                render: () =>
                                    <div  style={{backgroundColor: '#FFBF00', color: 'black'}}>
                                        
                                       <table>
                                            <tbody>
                                                {rowData?.notes && rowData.notes.length > 0 ?
                                                <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    <td style={{ padding: '16px', paddingLeft: '60px'}}><b>Notes: </b></td>
                                                    <td colSpan="100" style={{ padding: '16px' }}>{rowData.notes ? rowData.notes : "N/A"}</td>
                                                </tr> : null}
                                                
                                            
                                            </tbody>
                                        </table>
        
                                        <table style={{width:'100%'}}>
                                            <tbody>
                                                {rowData?.photoURLs && rowData.photoURLs.length > 0 ? <React.Fragment>
                                                <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    <td style={{ padding: '16px', paddingLeft: '60px'}}><b>Photos: </b></td>
                                                </tr>
                                                
                                                    
                                                </React.Fragment>  : null }
                                            </tbody>
                                        </table>
        
                                        <table style={{width:'100%'}}>
                                            <tbody>
                                            {rowData?.checklist && rowData?.checklist.length > 0 ? 
                                                <React.Fragment><tr>
                                                    <td colSpan="10"><Divider /></td>
                                                </tr> 
                                                <tr>
                                                <td style={{ paddingLeft: '60px'}}><b>Checklists </b></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="1" style={{ padding: '16px', paddingLeft: '60px' }}><b>Service Type</b></td>
                                                    <td colSpan="1" style={{ padding: '16px' }}><b>Service Reason</b></td>
                                                    <td colSpan="1" style={{ padding: '16px' }}><b>Sand Amount</b></td>
                                                    <td colSpan="1" style={{ padding: '16px' }}><b>Date</b></td>
                                                    <td colSpan="1" style={{ padding: '16px' }}><b>Equipment</b></td>
                                                    <td colSpan="1" style={{ padding: '16px' }}><b>Link</b></td>
                                                </tr></React.Fragment>
                                                : null}
                                           
                                                {rowData?.checklist && rowData?.checklist?.length > 0 ? 
                                                rowData.checklist.map((checklist, index) => {
                                                    return <tr key={index}>
                                                    <td colSpan="1" style={{ padding: '16px', paddingLeft: '60px' }}>{checklist.serviceType}</td>
                                                    <td colSpan="1" style={{ padding: '16px' }}>{checklist.serviceReason}</td>
                                                    <td colSpan="1" style={{ padding: '16px' }}>{checklist.sandAmount ? checklist.sandAmount : "None"}</td>
                                                    <td colSpan="1" style={{ padding: '16px' }}>{checklist.date < 9999999999 ? moment(checklist.date *= 1000).format('YYYY-MM-DD') : moment(checklist.date).format('YYYY-MM-DD')}</td>
                                                    <td colSpan="1" style={{ padding: '16px' }}>{checklist.equipmentType ? Object.values(checklist.equipmentType).join(", ") : "N/A"}</td>
                                                    <td colSpan="1" style={{ padding: '16px' }}><IconButton color="secondary"  onClick={(event) => 
                                                    props.history.push({
                                                        pathname: "/dashboard/reporting/timesheets/checklist",
                                                        search: "?" + new URLSearchParams({ docId: checklist.docId }).toString(),
                                                    })}><TableIcons.OpenInNew/></IconButton></td>
                                                </tr>
                                                    //<img key={index} style={{height: '300px', width: '300px'}} src={image} alt={index} />
                                                    
                                                })
                                                : null}
                                            </tbody>
                                        </table>
                                    </div>
                            })
                        ]}
                        localization={{
                            pagination: {
                                //first-last of total
                              //labelDisplayedRows: 'string here',
                            }
                          }}
                          components={{
                            Toolbar: props => (
                              <div>
                                <MTableToolbar {...props} />
                                    <div style={{padding: '0px 10px'}}>
                                    <Button 
                                        variant="contained" 
                                        size={"small"} 
                                        style={{marginRight: 5}} 
                                        onClick={() => {setSelecting(!selecting); sessionStorage.setItem('timesheetSelecting', JSON.stringify(!selecting))}}>
                                            {selecting ? 'Cancel Selecting' : 'Select For Print'}
                                    </Button>
                                        {selecting ? 
                                            <Button
                                                variant="contained"
                                                size={"small"}
                                                style={{marginRight: 5}}
                                                onClick={() => handlePrintSelected()}
                                            >
                                                Print Selected
                                            </Button> 
                                        : null}

                                        <Button 
                                            variant='contained'
                                            size={"small"} 
                                            style={{marginLeft: '15px'}}
                                            onClick={() => {setCreateNewModal(true)}}
                                        >
                                            Create New
                                        </Button>
                                    </div>
                              </div>
                            ),
                          }}
                        onPageChange={ (pageNum) => { sessionStorage.setItem("timesheetReportPageValue", JSON.stringify(pageNum)) }}
                        options={{
                            pageSize: "timesheetReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetReportRowsPerPage")) : 25,
                            pageSizeOptions: [15, 25, 30,],
                            initialPage: "timesheetReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("timesheetReportPageValue")) : 0,
                            doubleHorizontalScroll: true,
                            showSelectAllCheckbox: true,
                            toolbar: true,
                            columnsButton: true,
                            paging: true,
                            selection: selecting,
                            sorting: true,
                            thirdSortClick: false,
                            searchText: searchBarValue,
                            rowStyle: rowData => {
                                if (rowData.timesheetID === "Unsubmitted") {
                                    return { backgroundColor: darkMode === 'dark'?'#3b0216':'#ff99bd'};
                                }
                                if (rowData.approved === true && (rowData.payrolled === false || _.isUndefined(rowData.payrolled))) {
                                    return { backgroundColor:  darkMode === 'dark'?'#01360d':'lightGreen' };
                                }
                                if (rowData.payrolled === true && rowData.approved === true) {
                                    return { backgroundColor:  darkMode === 'dark'?'#011738':'lightBlue' };
                                }
                                return {};
                            }
                        }}
                    />
                    : <Skeleton variant='rect' width={'80vw'} height={'205vh'}/>}
                <div className={classes.sidebarSpacer}>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon style={{ fontSize: '50px' }} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto">
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <TimesheetOptions
                                checkboxState={checkboxState} handler={checkboxHandler}
                                searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                                clearSearch={clearSearch}
                                typeSearchValue={typeAutoCompleteValue} typeSearchValueChanged={setTypeAutoCompleteValue}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                                selecting={selecting} setSelecting={setSelecting}
                                selectedData={selectedData} handlePrintSelected={handlePrintSelected}
                                handlePrint={handlePrint} handlePrintTotal={handlePrintTotal} handlePrintPayroll={handlePrintPayroll}
                                searchState={searchState} searchHandler={searchHandler}
                            />
                            <Divider style={{ marginTop: '4%' }} />
                            <Typography><small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Unapproved: {unapprovedSheets}</small></Typography>
                            <Typography><small style={{backgroundColor:darkMode === 'dark'?'#01360d':'lightGreen', borderRadius:'10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;</small>&nbsp;<small>Total Approved: {approvedSheets}</small></Typography>
                            <Typography><small style={{backgroundColor:darkMode === 'dark'?'#011738':'lightBlue', borderRadius:'10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;</small>&nbsp;<small>Total Payrolled: {payrolledSheets}</small></Typography>
                            <Typography><small style={{backgroundColor:darkMode === 'dark'?'#3b0216':'#ff99bd', borderRadius:'10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;</small>&nbsp;<small>Total Unsubmit: {unsubmittedSheets}</small></Typography>
                            <Divider/>
                            <Typography><small>Total Printed: {printedSheets}</small></Typography>
                        </Paper>
                    </Collapse>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(TimesheetReport)